import { createSelector } from 'reselect'

import {
  proLicenseExpirySelector,
  plottrLicenseExpirySelector,
  hasAPlottrLicenseExpiredOrNotSelector,
  hasAProLicenseExpiredOrNotSelector,
} from './licenseFirstOrder'
import { choseProModeSelector } from './settingsFirstOrder'

const oldDate = new Date('2020-01-02T00:00:00.001Z')

export const relevantExpiryDateSelector = createSelector(
  proLicenseExpirySelector,
  plottrLicenseExpirySelector,
  hasAPlottrLicenseExpiredOrNotSelector,
  hasAProLicenseExpiredOrNotSelector,
  choseProModeSelector,
  (
    proLicenseExpiry,
    plottrLicenseExpiry,
    hasAPlottrLicenseExpiredOrNot,
    hasAProLicenseExpiredOrNot,
    choseProMode
  ) => {
    if (choseProMode) {
      if (hasAProLicenseExpiredOrNot) {
        return proLicenseExpiry || null
      } else {
        return oldDate
      }
    } else {
      if (hasAPlottrLicenseExpiredOrNot) {
        return plottrLicenseExpiry || null
      } else {
        return oldDate
      }
    }
  }
)
