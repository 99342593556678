/**
 * @param {String} x
 * @return {number}
 */
export const safeParseInt = (x) => {
  try {
    return parseInt(x)
  } catch (_e) {
    try {
      return parseInt(x.replace(/[^0-9]/g, ''))
    } catch (_e2) {
      return 0
    }
  }
}
