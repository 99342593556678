// IMPORTANT NOTE: Please don't import other selectors from this file.
// Use secondOrder and *ThirdOrder for your selector if it has other
// dependencies.

import { createSelector } from 'reselect'

import { fullFileStateSelector } from './fullFileFirstOrder'

export const allFamilyTreesSelector = createSelector(
  fullFileStateSelector,
  ({ familyTrees }) => familyTrees ?? {}
)

const familyTreeIdSelector = (_state, id) => {
  return id
}
export const familyTreeSelector = createSelector(
  allFamilyTreesSelector,
  familyTreeIdSelector,
  (familyTrees, id) => {
    return familyTrees[id]
  }
)
