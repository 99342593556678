import { DateTime } from 'luxon'

/**
 * @param {String} string
 * @return {number | Null}
 */
const safeParseInt = (string) => {
  if (typeof string === 'string' && string.match(/[0-9]+/)) {
    try {
      const x = parseInt(string)
      if (isNaN(x)) {
        return null
      } else {
        return x
      }
    } catch (error) {
      return null
    }
  } else {
    return null
  }
}

/**
 * Parse the supplied string-formated date.  Expect something of the
 * form YYYY_MM?_DD? or YYYY-MM?-DD?.
 *
 * Also account for odd spaces in the date and try to extract a date
 * regardless of errors.
 *
 * Throw an error if it's not possible to produce a date even after
 * cleaning it up.
 *
 * @param {String} stringDate
 * @return {Date}
 */
export const robustlyParseStringDate = (stringDate) => {
  const components = stringDate.split(/[-_]/)
  const errorMessage = `Invalid date ${stringDate}.  Could not find a year, month and day component.`
  if (components.length < 3 || !components[0] || !components[1] || !components[2]) {
    throw new Error(errorMessage)
  } else {
    const yearComponent = components[0].split(/[^0-9]/).find((subComponent) => {
      return subComponent.length === 4
    })
    const monthComponent = components[1].split(/[^0-9]/).find((subComponent) => {
      return subComponent.length <= 2
    })
    const dayComponent = components[2].split(/[^0-9]/).find((subComponent) => {
      return subComponent.length <= 2
    })
    if (!yearComponent || !monthComponent || !dayComponent) {
      throw new Error(errorMessage)
    } else {
      const year = safeParseInt(yearComponent)
      const month = safeParseInt(monthComponent)
      const day = safeParseInt(dayComponent)
      if (!year || !month || !day) {
        throw new Error(errorMessage)
      } else {
        return new Date(year, month - 1, day)
      }
    }
  }
}

// Expect a string of the form: YYYY_MM?_DD? to a date.
// This is necessary because date parsing is *browser specific*!
export const parseStringDate = (stringDate) => {
  const [year, month, day] = stringDate.split('_')
  if (!year || !month || !day) {
    throw new Error(
      `Invalid date: ${stringDate}.  Expected something in the form: YYYY_MM?_DD? or YYYY-MM?-DD?`
    )
  }
  return new Date(year, month - 1, day)
}

export const versionToDate = (version) => {
  if (typeof version === 'string') {
    const [rawYear, rawMonth, rawDay] = version.split('.')
    const year = safeParseInt(rawYear)
    const month = safeParseInt(rawMonth)
    const day = safeParseInt(rawDay)
    if (year && month && day) {
      const versionDate = new Date()
      versionDate.setUTCFullYear(year)
      versionDate.setUTCMonth(month - 1)
      versionDate.setUTCDate(day)
      return versionDate
    } else {
      return null
    }
  } else {
    return null
  }
}

/**
 * Produce a new JS Date that is MONTHS months in the past from the
 * given DATE.
 *
 * @param {Date} date
 * @param {number} months
 * @returns {Date}
 */
export const subtractMonths = (date, months) => {
  return DateTime.fromJSDate(date).minus({ months }).toJSDate()
}

/**
 * Produce a new JS Date that is MONTHS months in the future from the
 * given DATE.
 *
 * @param {Date} date
 * @param {number} months
 * @returns {Date}
 */
export const addMonths = (date, months) => {
  return DateTime.fromJSDate(date).plus({ months }).toJSDate()
}
