import { combineReducers } from 'redux'
import beats from './beats'
import books from './books'
import cards from './cards'
import categories from './categories'
import characters from './characters'
import customAttributes from './customAttributes'
import attributes from './attributes'
import file from './file'
import images from './images'
import lines from './lines'
import notes from './notes'
import places from './places'
import series from './series'
import tags from './tags'
import ui from './ui'
import hierarchyLevels from './hierarchy'
import featureFlags from './featureFlags'
import error from './error'
import permission from './permission'
import project from './project'
import client from './client'
import license from './license'
import knownFiles from './knownFiles'
import templates from './templates'
import settings from './settings'
import backups from './backups'
import applicationState from './applicationState'
import imageCache from './imageCache'
import notifications from './notifications'
import domEvents from './domEvents'
import testingAndDiagnosis from './testingAndDiagnosis'
import undo from './undo'
import offline from './offline'
import account from './account'
import familyTrees from './familyTree'

// normally it would make more sense to alphabetize them
// but for customer service, it helps a lot to have them in a specific order
// to pick out some important things at the top
const mainReducer = (dataRepairers) =>
  combineReducers({
    user: combineReducers({
      file: file(dataRepairers),
      ui: ui(dataRepairers),
      featureFlags: featureFlags(dataRepairers),
      series: series(dataRepairers),
      books: books(dataRepairers),
      beats: beats(dataRepairers),
      cards: cards(dataRepairers),
      categories: categories(dataRepairers),
      characters: characters(dataRepairers),
      customAttributes: customAttributes(dataRepairers),
      attributes: attributes(dataRepairers),
      lines: lines(dataRepairers),
      notes: notes(dataRepairers),
      places: places(dataRepairers),
      tags: tags(dataRepairers),
      hierarchyLevels: hierarchyLevels(dataRepairers),
      images: images(dataRepairers),
      familyTrees: familyTrees(dataRepairers),
    }),
    system: combineReducers({
      error: error(dataRepairers),
      permission: permission(dataRepairers),
      project,
      client,
      license,
      account,
      knownFiles,
      templates,
      settings,
      backups,
      applicationState,
      imageCache,
      notifications,
      domEvents,
      testingAndDiagnosis,
      undo,
      offline,
    }),
  })

export default mainReducer
