// data structure

import { nextLevelName } from '../helpers/hierarchy'
import { nextBorderStyle } from './borderStyle'
import { nextColor, nextDarkColor } from './lineColors'

export const RCE_INITIAL_VALUE = [{ type: 'paragraph', children: [{ text: '' }] }]

export const series = {
  name: '',
  premise: '',
  genre: '',
  theme: '',
  templates: [],
}

export const book = {
  id: 1,
  title: '',
  premise: '',
  genre: '',
  theme: '',
  templates: [],
  timelineTemplates: [],
  imageId: null,
}

export const beat = {
  id: 1,
  bookId: 'series',
  position: 0,
  title: 'auto',
  time: 0, // ? can also be a string
  templates: [],
  autoOutlineSort: true,
  fromTemplateId: null,
  expanded: true,
}

export const chapter = {
  id: 2,
  bookId: 1,
  position: 0,
  title: 'auto',
  time: 0, // ? can also be a string
  templates: [],
  autoOutlineSort: true,
  fromTemplateId: null,
  expanded: true,
}

export const uiState = {
  currentView: 'timeline',
  currentTimeline: 1, // bookId or 'series'
  timelineIsExpanded: true,
  orientation: 'horizontal',
  darkMode: false, // <- Deprecated, but keep here for a while for backwards compatibility.
  characterSort: 'name~asc',
  characterFilter: {},
  placeSort: 'name~asc',
  placeFilter: null,
  noteSort: 'title~asc',
  noteFilter: null,
  outlineFilter: null,
  timelineFilter: null,
  timelineScrollPosition: {
    x: 0,
    y: 0,
  },
  timeline: {
    pinnedPlotlines: {},
    size: 'large',
    view: 'default',
    actTab: 0,
    focus: [],
    beatToDelete: null,
    contextMenuBeat: null,
    editingBeatId: null,
    beatHeadingTitleBeingEdited: null,
    plotlineTitleBeingEdited: null,
  },
  searchTerms: {
    notes: null,
    characters: null,
    places: null,
    tags: null,
    outline: null,
    timeline: null,
  },
  attributeTabs: {
    characters: 'all',
  },
  characterTab: {
    selectedCharacter: null,
    focus: [],
    attributesDialogOpen: false,
    categoriesDialogOpen: false,
    editingSelected: false,
    showTemplatePicker: false,
    creating: false,
    templateData: null,
    filterVisible: false,
    sortVisible: false,
    detailsVisible: true,
    characterEditor: {
      deleting: false,
      removing: false,
      removeWhichTemplate: null,
      activeTab: 1,
      showTemplatePicker: false,
    },
    characterSelectedForChangingImage: null,
    relationshipEditorOpen: false,
  },
  customAttributeOrder: {
    characters: [],
  },
  cardDialog: {
    isOpen: false,
    cardId: null,
    lineId: null,
    beatId: null,
    deleting: false,
    showColorPicker: false,
    showTemplatePicker: false,
    removing: false,
    removeWhichTemplate: null,
  },
  collaborators: {},
  bookDialog: {
    isOpen: false,
    bookId: null,
  },
  searchDialog: {
    isOpen: false,
    term: '',
    currentHitIndex: null,
    scanning: false,
    replacing: false,
    replacement: '',
    hitsToReplace: [],
  },
  projectTab: {
    focus: [],
  },
  outlineTab: {
    focus: [],
    selectedCard: null,
    cardEditor: {
      editing: null,
    },
    layout: {
      view: 'plan',
      fulltext: {
        showCardTitle: true,
        showBeatTitle: true,
        showLayoutConfig: true,
        showBeatGaps: true,
        cardTitleAlignment: 'left',
        beatTitleAlignment: 'left',
        hoverDetails: {
          lineTitle: null,
          beatTitle: null,
          cardTitle: null,
          visible: false,
        },
      },
    },
  },
  noteTab: {
    focus: [],
    selectedNote: null,
    editingSelected: false,
    categoriesDialogOpen: false,
    attributesDialogOpen: false,
    filterVisible: false,
    sortVisible: false,
  },
  placeTab: {
    focus: [],
    selectedPlace: null,
    attributeDialogOpen: false,
    editingSelected: false,
    categoriesOpen: false,
    filterVisible: false,
    sortVisible: false,
  },
  tagTab: {
    focus: [],
    selectedTag: null,
    editingSelectedTab: false,
  },
  outlineScrollPosition: 0,
  templateModal: {
    expanded: false,
  },
  actConfigModal: {
    open: false,
  },
  resturctureTimelineModal: {
    open: false,
  },
  dashboardModal: {
    view: null,
  },
  conflictResolutionModal: {
    open: false,
  },
  familyTree: {
    currentFamilyTree: 0,
    selectedRelationshipType: null,
    showColourPicker: false,
  },
}

export const ui = {
  ...uiState,
  collaborators: {
    collaborators: [],
    viewers: [],
  },
}

export const attributes = {
  characters: [],
}

export const notifications = {
  message: null,
  toast: {
    cardAction: null,
    newBookId: null,
    visible: false,
  },
}

export const file = {
  fileName: '',
  loaded: false,
  dirty: false,
  version: '',
}

export const character = {
  id: 1,
  name: '',
  description: '',
  notes: RCE_INITIAL_VALUE,
  color: null,
  cards: [],
  noteIds: [],
  templates: [],
  tags: [],
  categoryId: null,
  imageId: null,
  bookIds: [],
  position: 0,
  attributes: [],
}

export const categories = {
  characters: [],
  places: [],
  notes: [],
  tags: [],
}

export const category = {
  id: '',
  name: '',
  position: 0,
}

export const place = {
  id: 1,
  name: '',
  description: '',
  notes: RCE_INITIAL_VALUE,
  color: null,
  cards: [],
  noteIds: [],
  templates: [],
  tags: [],
  imageId: null,
  bookIds: [],
  position: 0,
}

export const tag = {
  id: 1,
  title: '',
  color: null,
}

export const card = {
  id: 1,
  lineId: null,
  beatId: null,
  bookId: null,
  positionWithinLine: 0,
  positionInBeat: 0,
  title: '',
  description: RCE_INITIAL_VALUE,
  tags: [],
  characters: [],
  places: [],
  templates: [],
  imageId: null,
  fromTemplateId: null,
  color: null,
}

export const line = {
  id: 1,
  bookId: 1,
  color: '#6cace4',
  title: '',
  position: 0,
  characterId: null,
  expanded: null,
  fromTemplateId: null,
  isPinned: false,
}

export const seriesLine = {
  id: 2,
  bookId: 'series',
  color: '#6cace4',
  title: '',
  position: 0,
  characterId: null,
  expanded: null,
  fromTemplateId: null,
}

export const customAttributes = {
  characters: [],
  places: [],
  scenes: [],
  lines: [],
  notes: [],
}

export const attribute = {
  name: '',
  type: 'text',
}

export const note = {
  id: 1,
  title: '',
  content: RCE_INITIAL_VALUE,
  categoryId: null,
  tags: [],
  characters: [],
  places: [],
  lastEdited: null,
  templates: [],
  imageId: null,
  bookIds: [],
  position: 0,
}

export const image = {
  id: 1,
  name: '',
  path: '',
  data: '',
}

// example template for reference
// NOT exported
// const templates = [
//   { id: 'ch1', version: '2020.3.4', attributes: [{ name: 'Motivation', type: 'text', value: '' }] },
// ]

export const hierarchyLevel = () => {
  return {
    name: nextLevelName('default'),
    level: 0,
    autoNumber: true,
    textSize: 24,
    borderStyle: nextBorderStyle(0),
    backgroundColor: 'none', // Same as app canvas
    textColor: nextColor('default'),
    borderColor: nextColor(0),
    dark: {
      borderColor: nextDarkColor(0),
      textColor: nextDarkColor(0),
    },
    light: {
      borderColor: nextColor(0),
      textColor: nextColor('default'),
    },
  }
}

export const featureFlags = {}

export const tour = {
  showTour: false,
  // feature:{
  //   name:'',
  //   id:0,
  //   endstep:null
  // }, // XXXXXX use this blank object once the user can select the feature to tour
  feature: {
    name: 'acts',
    id: 1,
    endStep: 8,
  },
  run: true,
  //continuous: true, // won't they all be either continuous or not? - may need to include a continuous key/value in the 'feature' object above depending on which feature, some may not be continuous
  stepIndex: 0,
  steps: [],
  loading: false,
  action: 'start',
  transitioning: false,
  b2bTransition: false,
  toursTaken: {},
  // key: new Date(), // may need this to restart tours when more tours are added
}

export const newHierarchyLevel = (allHierarchyLevels) => {
  return {
    ...hierarchyLevel(),
    name: nextLevelName(allHierarchyLevels.length),
    level: 0,
    borderStyle: nextBorderStyle(allHierarchyLevels.length),
    textColor: nextColor(allHierarchyLevels.length),
    borderColor: nextColor(allHierarchyLevels.length),
    dark: {
      textColor: nextDarkColor(allHierarchyLevels.length),
      borderColor: nextDarkColor(allHierarchyLevels.length),
    },
    light: {
      textColor: nextColor(allHierarchyLevels.length),
      borderColor: nextColor(allHierarchyLevels.length),
    },
  }
}

/**
 * @typedef Relationship
 * @property {number} characterId
 * @property {"parent"|"sibling"|"child"} relationshipType
 * @property {string} relationshipName
 *
 * @typedef Member
 * @property {number} characterId
 * @property {number} x
 * @property {number} y
 * @property {Array<Relationship>} relationships
 */
export const emptyFamilyTree = {
  id: 0,
  name: '',
  stage: {
    scale: [1, 1],
    x: 0,
    y: 0,
  },
  members: {},
  relationshipConfiguration: {
    Parent: {
      type: 'parent',
      name: 'Parent',
      colour: '#e5554f',
    },
    Sibling: {
      type: 'sibling',
      name: 'Sibling',
      colour: '#6cace4',
    },
    Child: {
      type: 'child',
      name: 'Child',
      colour: '#78be20',
    },
  },
}
