import { serializeNoFormatting } from '../slate_serializers/to_plain_text'
import { escapeUIPathElement } from '../helpers/ui'

export const literalRegExp = (unescapedTerm, replaceWord) => {
  const flags = [...unescapedTerm].some((c) => c.match(/\w/) && c.toLocaleUpperCase() === c)
    ? 'g'
    : 'gi'
  return new RegExp(
    (replaceWord ? '(?:^|\\W)(?<term>' : '') +
      unescapedTerm.replace(/[\\[\](){}$^\-.*+?|/]/g, '\\$&') +
      (replaceWord ? ')(?:$|\\W)' : ''),
    flags
  )
}

const HIT_LIMIT = 30
export const hits = (pathSansPosition, regexMatches) => {
  const results = []
  let result = null
  let count = 0
  while (((result = regexMatches.next()), !result.done && count++ < HIT_LIMIT)) {
    const isGroupMatch = !!result.value.groups?.term
    const value = result.value.groups?.term ?? result.value[0]
    const position =
      result.value.index + (isGroupMatch ? (result.value[0].match(/^\W/) ? 1 : 0) : 0)
    results.push({
      path: `${pathSansPosition}/${position}`,
      hit: value,
    })
  }
  return results
}

export const characterSearchHits = (
  term,
  allCharacters,
  allAttributes,
  allBooks,
  legacyCustomAttributes,
  replaceWord
) => {
  if (term === '' || !term || term.length < 3) {
    return []
  }

  const characterMatch = (character) => {
    const nameMatch = character.name.matchAll(literalRegExp(term, replaceWord))
    const characterCustomAttributes = (character.attributes || []).reduce((acc, attribute) => {
      const indexAttribute = allAttributes.characters.find(({ id }) => {
        return id === attribute.id
      })
      if (indexAttribute.type === 'base-attribute' && indexAttribute.name === 'tags') {
        return acc
      } else {
        const valueAsString =
          (Array.isArray(attribute.value)
            ? serializeNoFormatting(attribute.value)
            : String(attribute.value)) || ''
        const valueMatch = valueAsString.matchAll(literalRegExp(term, replaceWord))

        const bookTitle = attribute.bookId === 'all' ? 'Series' : allBooks[attribute.bookId]?.title
        const bookId = attribute.bookId
        if (!valueMatch || !indexAttribute || !bookTitle) {
          return acc
        } else {
          return [
            ...hits(
              `/characters/${character.id}/customAttribute/${attribute.id}/${bookId}`,
              valueMatch
            ),
            ...acc,
          ]
        }
      }
    }, [])
    const characterTemplateAttributes = character.templates.reduce((acc, template) => {
      const templateAttributes = (template.values || []).flatMap((attribute) => {
        const valueAsString =
          (Array.isArray(attribute.value)
            ? serializeNoFormatting(attribute.value)
            : String(attribute.value)) || ''
        const valueMatch = valueAsString.matchAll(literalRegExp(term, replaceWord))
        const indexAttribute = template.attributes.find(({ name }) => {
          return name === attribute.name
        })
        const bookTitle = attribute.bookId === 'all' ? 'Series' : allBooks[attribute.bookId]?.title
        const bookId = attribute.bookId
        if (!valueMatch || !indexAttribute || !bookTitle) {
          return []
        }
        return hits(
          `/characters/${character.id}/templateAttribute/${template.id}/${escapeUIPathElement(
            attribute.name
          )}/${bookId}`,
          valueMatch
        )
      })
      return [...acc, ...templateAttributes]
    }, [])
    const characterLegacyAttributesHits = [
      'notes',
      'description',
      ...legacyCustomAttributes.map(({ name }) => {
        return name
      }),
    ]
      .filter((attributeName) => {
        return !(character.attributes || []).some((attribute) => {
          const indexAttribute = allAttributes.characters.find(({ id }) => {
            return id === attribute.id
          })
          const attributeAttributeName = attributeName === 'notes' ? 'description' : attributeName
          return indexAttribute.name === attributeAttributeName && attribute.bookId === 'all'
        })
      })
      .flatMap((attributeName) => {
        const attributeValue = character[attributeName]
        const valueAsString =
          (Array.isArray(attributeValue)
            ? serializeNoFormatting(attributeValue)
            : String(attributeValue)) || ''
        const valueMatch = valueAsString.matchAll(literalRegExp(term, replaceWord))
        return hits(
          `/characters/${character.id}/customAttribute/${escapeUIPathElement(attributeName)}/all`,
          valueMatch
        )
      })
    return [
      ...[nameMatch ? hits(`/characters/${character.id}/name`, nameMatch) : []].flatMap((x) => x),
      ...characterCustomAttributes,
      ...characterTemplateAttributes,
      ...characterLegacyAttributesHits,
    ]
  }

  return allCharacters.flatMap(characterMatch).filter((hit) => {
    return hit.hit.length > 0
  })
}
