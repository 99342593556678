export const INITIALISE_WORKER = 'INITIALISE_WORKER'
export const INITIALISE_WORKER_REPLY = 'INITIALISE_WORKER_REPLY'

export const EDIT_FILE_NAME = 'EDIT_FILE_NAME'
export const EDIT_FILE_NAME_REPLY = 'EDIT_FILE_NAME_REPLY'
export const UPDATE_AUTH_FILE_NAME = 'UPDATE_AUTH_FILE_NAME'
export const UPDATE_AUTH_FILE_NAME_REPLY = 'UPDATE_AUTH_FILE_NAME_REPLY'
export const UPDATE_AUTH_FILE_LAST_MODIFIED = 'UPDATE_AUTH_FILE_LAST_MODIFIED'
export const UPDATE_AUTH_FILE_LAST_MODIFIED_REPLY = 'UPDATE_AUTH_FILE_LAST_MODIFIED_REPLY'
export const LISTEN = 'LISTEN'
export const LISTEN_REPLY = 'LISTEN_REPLY'
export const LISTEN_UNSUBSCRIBE = 'LISTEN_UNSUBSCRIBE'
export const WITH_FILE_ID = 'WITH_FILE_ID'
export const WITH_FILE_ID_REPLY = 'WITH_FILE_ID_REPLY'
export const TO_FIRESTORE_ARRAY = 'TO_FIRESTORE_ARRAY'
export const TO_FIRESTORE_ARRAY_REPLY = 'TO_FIRESTORE_ARRAY_REPLY'
export const OVERWRITE_ALL_KEYS = 'OVERWRITE_ALL_KEYS'
export const OVERWRITE_ALL_KEYS_REPLY = 'OVERWRITE_ALL_KEYS_REPLY'
export const INITIAL_FETCH = 'INITIAL_FETCH'
export const FETCH_FILE_JSON = 'FETCH_FILE_JSON'
export const INITIAL_FETCH_REPLY = 'INITIAL_FETCH_REPLY'
export const FETCH_FILE_JSON_REPLY = 'FETCH_FILE_JSON_REPLY'
export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_FILE_REPLY = 'DELETE_FILE_REPLY'
export const STOP_LISTENING = 'STOP_LISTENING'
export const STOP_LISTENING_REPLY = 'STOP_LISTENING_REPLY'
export const LISTEN_TO_FILES = 'LISTEN_TO_FILES'
export const LISTEN_TO_FILES_REPLY = 'LISTEN_TO_FILES_REPLY'
export const LISTEN_TO_FILES_UNSUBSCRIBE = 'LISTEN_TO_FILES_UNSUBSCRIBE'
export const FETCH_FILES = 'FETCH_FILES'
export const FETCH_FILES_REPLY = 'FETCH_FILES_REPLY'
export const FETCH_FILE = 'FETCH_FILE'
export const FETCH_FILE_REPLY = 'FETCH_FILE_REPLY'
export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_REPLY = 'LOG_OUT_REPLY'
export const MINT_COOKIE_TOKEN = 'MINT_COOKIE_TOKEN'
export const MINT_COOKIE_TOKEN_REPLY = 'MINT_COOKIE_TOKEN_REPLY'
export const ON_SESSION_CHANGE = 'ON_SESSION_CHANGE'
export const ON_SESSION_CHANGE_REPLY = 'ON_SESSION_CHANGE_REPLY'
export const ON_SESSION_CHANGE_UNSUBSCRIBE = 'ON_SESSION_CHANGE_UNSUBSCRIBE'
export const FIREBASE_UI = 'FIREBASE_UI'
export const FIREBASE_UI_REPLY = 'FIREBASE_UI_REPLY'
export const CURRENT_USER = 'CURRENT_USER'
export const CURRENT_USER_REPLY = 'CURRENT_USER_REPLY'
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO'
export const GET_ACCOUNT_INFO_REPLY = 'GET_ACCOUNT_INFO_REPLY'
export const HAS_UNDEFINED_VALUE = 'HAS_UNDEFINED_VALUE'
export const HAS_UNDEFINED_VALUE_REPLY = 'HAS_UNDEFINED_VALUE_REPLY'
export const PATCH = 'PATCH'
export const PATCH_REPLY = 'PATCH_REPLY'
export const DELETE_SINGLE = 'DELETE_SINGLE'
export const DELETE_SINGLE_REPLY = 'DELETE_SINGLE_REPLY'
export const OVERWRITE = 'OVERWRITE'
export const OVERWRITE_REPLY = 'OVERWRITE_REPLY'
export const OVERWRITE_ALL = 'OVERWRITE_ALL'
export const OVERWRITE_ALL_REPLY = 'OVERWRITE_ALL_REPLY'
export const SHARE_DOCUMENT = 'SHARE_DOCUMENT'
export const SHARE_DOCUMENT_REPLY = 'SHARE_DOCUMENT_REPLY'
export const UNSHARE_DOCUMENT = 'UNSHARE_DOCUMENT'
export const UNSHARE_DOCUMENT_REPLY = 'UNSHARE_DOCUMENT_REPLY'
export const PUBLISH_RCE_OPERATIONS = 'PUBLISH_RCE_OPERATIONS'
export const PUBLISH_RCE_OPERATIONS_REPLY = 'PUBLISH_RCE_OPERATIONS_REPLY'
export const CATCHUP_EDITS_SEEN = 'CATCHUP_EDITS_SEEN'
export const CATCHUP_EDITS_SEEN_REPLY = 'CATCHUP_EDITS_SEEN_REPLY'
export const RELEASE_RCE_LOCK = 'RELEASE_RCE_LOCK'
export const RELEASE_RCE_LOCK_REPLY = 'RELEASE_RCE_LOCK_REPLY'
export const LOCK_RCE = 'LOCK_RCE'
export const LOCK_RCE_REPLY = 'LOCK_RCE_REPLY'
export const LISTEN_FOR_RCE_LOCK = 'LISTEN_FOR_RCE_LOCK'
export const LISTEN_FOR_RCE_LOCK_REPLY = 'LISTEN_FOR_RCE_LOCK_REPLY'
export const LISTEN_FOR_RCE_LOCK_UNSUBSCRIBE = 'LISTEN_FOR_RCE_LOCK_UNSUBSCRIBE'
export const LISTEN_FOR_CHANGES_TO_EDITOR = 'LISTEN_FOR_CHANGES_TO_EDITOR'
export const LISTEN_FOR_CHANGES_TO_EDITOR_REPLY = 'LISTEN_FOR_CHANGES_TO_EDITOR_REPLY'
export const DELETE_CHANGE_SIGNAL = 'DELETE_CHANGE_SIGNAL'
export const DELETE_CHANGE_SIGNAL_REPLY = 'DELETE_CHANGE_SIGNAL_REPLY'
export const DELETE_OLD_CHANGES = 'DELETE_OLD_CHANGES'
export const DELETE_OLD_CHANGES_REPLY = 'DELETE_OLD_CHANGES_REPLY'
export const FETCH_RCE_OPERATIONS = 'FETCH_RCE_OPERATIONS'
export const FETCH_RCE_OPERATIONS_REPLY = 'FETCH_RCE_OPERATIONS_REPLY'
export const SAVE_BACKUP = 'SAVE_BACKUP'
export const SAVE_BACKUP_REPLY = 'SAVE_BACKUP_REPLY'
export const LISTEN_FOR_BACKUPS = 'LISTEN_FOR_BACKUPS'
export const LISTEN_FOR_BACKUPS_REPLY = 'LISTEN_FOR_BACKUPS_REPLY'
export const LISTEN_FOR_BACKUPS_UNSUBSCRIBE = 'LISTEN_FOR_BACKUPS_UNSUBSCRIBE'
export const SAVE_CUSTOM_TEMPLATE = 'SAVE_CUSTOM_TEMPLATE'
export const SAVE_CUSTOM_TEMPLATE_REPLY = 'SAVE_CUSTOM_TEMPLATE_REPLY'
export const ALL_TEMPLATE_URLS_FOR_USER = 'ALL_TEMPLATE_URLS_FOR_USER'
export const ALL_TEMPLATE_URLS_FOR_USER_REPLY = 'ALL_TEMPLATE_URLS_FOR_USER_REPLY'
export const LISTEN_TO_CUSTOM_TEMPLATES = 'LISTEN_TO_CUSTOM_TEMPLATES'
export const LISTEN_TO_CUSTOM_TEMPLATES_REPLY = 'LISTEN_TO_CUSTOM_TEMPLATES_REPLY'
export const LISTEN_TO_CUSTOM_TEMPLATES_UNSUBSCRIBE = 'LISTEN_TO_CUSTOM_TEMPLATES_UNSUBSCRIBE'
export const EDIT_CUSTOM_TEMPLATE = 'EDIT_CUSTOM_TEMPLATE'
export const EDIT_CUSTOM_TEMPLATE_REPLY = 'EDIT_CUSTOM_TEMPLATE_REPLY'
export const DELETE_CUSTOM_TEMPLATE = 'DELETE_CUSTOM_TEMPLATE'
export const DELETE_CUSTOM_TEMPLATE_REPLY = 'DELETE_CUSTOM_TEMPLATE_REPLY'
export const SAVE_IMAGE_TO_STORAGE_BLOB = 'SAVE_IMAGE_TO_STORAGE_BLOB'
export const SAVE_IMAGE_TO_STORAGE_BLOB_REPLY = 'SAVE_IMAGE_TO_STORAGE_BLOB_REPLY'
export const SAVE_IMAGE_TO_STORAGE_FROM_URL = 'SAVE_IMAGE_TO_STORAGE_FROM_URL'
export const SAVE_IMAGE_TO_STORAGE_FROM_URL_REPLY = 'SAVE_IMAGE_TO_STORAGE_FROM_URL_REPLY'
export const BACKUP_PUBLIC_URL = 'BACKUP_PUBLIC_URL'
export const BACKUP_PUBLIC_URL_REPLY = 'BACKUP_PUBLIC_URL_REPLY'
export const IMAGE_PUBLIC_URL = 'IMAGE_PUBLIC_URL'
export const IMAGE_PUBLIC_URL_REPLY = 'IMAGE_PUBLIC_URL_REPLY'
export const IS_STORAGE_URL = 'IS_STORAGE_URL'
export const IS_STORAGE_URL_REPLY = 'IS_STORAGE_URL_REPLY'
export const LOGIN_WITH_EMAIL_AND_PASSWORD = 'LOGIN_WITH_EMAIL_AND_PASSWORD'
export const LOGIN_WITH_EMAIL_AND_PASSWORD_REPLY = 'LOGIN_WITH_EMAIL_AND_PASSWORD_REPLY'
export const GET_ID_TOKEN_RESULT = 'GET_ID_TOKEN_RESULT'
export const GET_ID_TOKEN_RESULT_REPLY = 'GET_ID_TOKEN_RESULT_REPLY'
export const DELETE_PRO_BACKUP = 'DELETE_PRO_BACKUP'
export const DELETE_PRO_BACKUP_REPLY = 'DELETE_PRO_BACKUP_REPLY'
export const WRITE_USER_OWNERSHIP_NOTE = 'WRITE_USER_OWNERSHIP_NOTE'
export const WRITE_USER_OWNERSHIP_NOTE_REPLY = 'WRITE_USER_OWNERSHIP_NOTE_REPLY'
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL'
export const SEND_PASSWORD_RESET_EMAIL_REPLY = 'SEND_PASSWORD_RESET_EMAIL_REPLY'
export const DELETE_MACHINE_LICENSE_ACTIVATION = 'DELETE_MACHINE_LICENSE_ACTIVATION'
export const DELETE_MACHINE_LICENSE_ACTIVATION_REPLY = 'DELETE_MACHINE_LICENSE_ACTIVATION_REPLY'

// Error replies
export const UPDATE_AUTH_FILE_LAST_MODIFIED_ERROR_REPLY =
  'UPDATE_AUTH_FILE_LAST_MODIFIED_ERROR_REPLY'
export const EDIT_FILE_NAME_ERROR_REPLY = 'EDIT_FILE_NAME_ERROR_REPLY'
export const OVERWRITE_ALL_KEYS_ERROR_REPLY = 'OVERWRITE_ALL_KEYS_ERROR_REPLY'
export const INITIAL_FETCH_ERROR_REPLY = 'INITIAL_FETCH_ERROR_REPLY'
export const DELETE_FILE_ERROR_REPLY = 'DELETE_FILE_ERROR_REPLY'
export const FETCH_FILES_ERROR_REPLY = 'FETCH_FILES_ERROR_REPLY'
export const FETCH_FILE_JSON_ERROR_REPLY = 'FETCH_FILE_JSON_ERROR_REPLY'
export const LOG_OUT_ERROR_REPLY = 'LOG_OUT_ERROR_REPLY'
export const MINT_COOKIE_TOKEN_ERROR_REPLY = 'MINT_COOKIE_TOKEN_ERROR_REPLY'
export const PATCH_ERROR_REPLY = 'PATCH_ERROR_REPLY'
export const OVERWRITE_ERROR_REPLY = 'OVERWRITE_ERROR_REPLY'
export const SHARE_DOCUMENT_ERROR_REPLY = 'SHARE_DOCUMENT_ERROR_REPLY'
export const UNSHARE_DOCUMENT_ERROR_REPLY = 'SHARE_DOCUMENT_ERROR_REPLY'
export const RELEASE_RCE_LOCK_ERROR_REPLY = 'RELEASE_RCE_LOCK_ERROR_REPLY'
export const LOCK_RCE_ERROR_REPLY = 'LOCK_RCE_ERROR_REPLY'
export const SAVE_BACKUP_ERROR_REPLY = 'SAVE_BACKUP_ERROR_REPLY'
export const SAVE_CUSTOM_TEMPLATE_ERROR_REPLY = 'SAVE_CUSTOM_TEMPLATE_ERROR_REPLY'
export const EDIT_CUSTOM_TEMPLATE_ERROR_REPLY = 'EDIT_CUSTOM_TEMPLATE_ERROR_REPLY'
export const DELETE_CUSTOM_TEMPLATE_ERROR_REPLY = 'DELETE_CUSTOM_TEMPLATE_ERROR_REPLY'
export const SAVE_IMAGE_TO_STORAGE_BLOB_ERROR_REPLY = 'SAVE_IMAGE_TO_STORAGE_BLOB_ERROR_REPLY'
export const SAVE_IMAGE_TO_STORAGE_FROM_URL_ERROR_REPLY =
  'SAVE_IMAGE_TO_STORAGE_FROM_URL_ERROR_REPLY'
export const BACKUP_PUBLIC_URL_ERROR_REPLY = 'BACKUP_PUBLIC_URL_ERROR_REPLY'
export const IMAGE_PUBLIC_URL_ERROR_REPLY = 'IMAGE_PUBLIC_URL_ERROR_REPLY'
export const GET_ID_TOKEN_RESULT_ERROR_REPLY = 'GET_ID_TOKEN_RESULT_ERROR_REPLY'
export const DELETE_PRO_BACKUP_ERROR_REPLY = 'DELETE_PRO_BACKUP_ERROR_REPLY'
export const LOGIN_WITH_EMAIL_AND_PASSWORD_ERROR_REPLY = 'LOGIN_WITH_EMAIL_AND_PASSWORD_ERROR_REPLY'
export const WRITE_USER_OWNERSHIP_NOTE_ERROR_REPLY = 'WRITE_USER_OWNERSHIP_NOTE_ERROR_REPLY'
export const SEND_PASSWORD_RESET_EMAIL_ERROR_REPLY = 'SEND_PASSWORD_RESET_EMAIL_ERROR_REPLY'
export const DELETE_MACHINE_LICENSE_ACTIVATION_ERROR_REPLY =
  'DELETE_MACHINE_LICENSE_ACTIVATION_ERROR_REPLY'
export const GET_ACCOUNT_INFO_ERROR_REPLY = 'GET_ACCOUNT_INFO_ERROR_REPLY'

// Specific to desktop
export const LOG_FROM_WORKER = 'LOG_FROM_WORKER'
