import { omit, capitalize, cloneDeep } from 'lodash'

import * as lineColors from '../store/lineColors'
import { newFileFamilyTrees } from '../store/newFileState'
import { emptyFamilyTree } from '../store/initialState'
import {
  FILE_LOADED,
  SAVE_FAMILY_TREE,
  NEW_RELATIONSHIP_TYPE,
  DELETE_SELECTED_RELATIONSHIP,
  SET_RELATIONSHIP_TYPE_COLOUR,
  SET_SELECTED_RELATIONSHIP_NAME,
  SET_SELECTED_RELATIONSHIP_TYPE,
  NEW_FAMILY_TREE,
  DELETE_FAMILY_TREE,
  EDIT_FAMILY_TREE_NAME,
  DELETE_CHARACTER,
  UNDO_N_TIMES,
  REDO_N_TIMES,
  UNDO,
  REDO,
  NEW_FILE,
  ACTION_CONFLICT_CHANGES,
  LOAD_FAMILY_TREES,
  LOAD_FAMILY_TREE,
  BATCH_LOAD_FAMILY_TREE,
  REMOVE_FAMILY_TREE,
} from '../constants/ActionTypes'
import { safeParseInt } from '../helpers/safeParseInt'
import { addConflictedFamilyTreeEntities } from './addConflictedEntities'

/**
 * @typedef {"parent"|"sibling"|"child"} RelationshipType
 */

/**
 * @typedef SingleRelationshipConfiguration
 * @property {RelationshipType} type
 * @property {String} name
 * @property {String} colour
 */

/**
 * @typedef {Record<String, SingleRelationshipConfiguration>} RelationshipConfiguration
 */

/**
 * @typedef Relationship
 * @property {Array<number>} [sharedSources]
 * @property {number} characterId
 * @property {string} relationshipName
 *
 * @typedef Member
 * @property {number} characterId
 * @property {number} x
 * @property {number} y
 * @property {Array<Relationship>} relationships
 */

/**
 * @typedef Family
 * @property {number} id
 * @property {string} name
 * @property {{ scale: [number, number], x: number, y: number }} stage
 * @property {Record<string, Member>} members
 * @property {RelationshipConfiguration} relationshipConfiguration
 */

const SIBLING = 'Sibling'
const PARENT = 'Parent'
const CHILD = 'Child'

const RELATIONSHIP_TO_COLOUR = {
  [capitalize(SIBLING)]: lineColors.nextColor(0),
  [capitalize(PARENT)]: lineColors.nextColor(1),
  [capitalize(CHILD)]: lineColors.nextColor(2),
}
const HARD_CODED_TEMPORARY_RELATIONSHIP_TYPES = []

/**
 * @type {Record<String, Family>}
 */
const INITIAL_STATE = {
  0: {
    id: 0,
    name: '',
    stage: {
      scale: [1, 1],
      x: 0,
      y: 0,
    },
    members: {},
    relationshipConfiguration: {
      [SIBLING]: {
        name: capitalize(SIBLING),
        type: 'sibling',
        colour: RELATIONSHIP_TO_COLOUR['Sibling'],
      },
      [PARENT]: {
        name: capitalize(PARENT),
        type: 'parent',
        colour: RELATIONSHIP_TO_COLOUR['Parent'],
      },
      [CHILD]: {
        name: capitalize(CHILD),
        type: 'child',
        colour: RELATIONSHIP_TO_COLOUR['Child'],
      },
    },
  },
}

const familyTreeReducer =
  (_dataRepairers) =>
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAVE_FAMILY_TREE: {
        const { familyTree } = action
        return {
          ...state,
          [familyTree.id]: familyTree,
        }
      }
      case SET_RELATIONSHIP_TYPE_COLOUR: {
        return {
          ...state,
          [action.familyTreeId]: {
            ...state[action.familyTreeId],
            relationshipConfiguration: {
              ...state[action.familyTreeId].relationshipConfiguration,
              [action.selectedRelationship]: {
                ...state[action.familyTreeId].relationshipConfiguration[
                  action.selectedRelationship
                ],
                colour: action.colour,
              },
            },
          },
        }
      }
      case SET_SELECTED_RELATIONSHIP_NAME: {
        if (state[action.familyTreeId].relationshipConfiguration[action.selectedRelationship]) {
          return {
            ...state,
            [action.familyTreeId]: {
              ...state[action.familyTreeId],
              relationshipConfiguration: {
                ...omit(
                  state[action.familyTreeId].relationshipConfiguration,
                  action.selectedRelationship
                ),
                [action.name]: {
                  ...state[action.familyTreeId].relationshipConfiguration[
                    action.selectedRelationship
                  ],
                  name: action.name,
                },
              },
            },
          }
        } else {
          return state
        }
      }
      case SET_SELECTED_RELATIONSHIP_TYPE: {
        return {
          ...state,
          [action.familyTreeId]: {
            ...state[action.familyTreeId],
            relationshipConfiguration: {
              ...state[action.familyTreeId].relationshipConfiguration,
              [action.selectedRelationship]: {
                ...state[action.familyTreeId].relationshipConfiguration[
                  action.selectedRelationship
                ],
                type: action.relationshipType,
              },
            },
          },
        }
      }
      case DELETE_SELECTED_RELATIONSHIP: {
        const relationshipConfigToDelete =
          state[action.familyTreeId].relationshipConfiguration[action.selectedRelationship]
        if (relationshipConfigToDelete) {
          return {
            ...state,
            [action.familyTreeId]: {
              ...state[action.familyTreeId],
              relationshipConfiguration: omit(
                state[action.familyTreeId].relationshipConfiguration,
                action.selectedRelationship
              ),
              members: Object.entries(state[action.familyTreeId].members).reduce((acc, next) => {
                const [key, val] = next
                if (
                  val.relationships.some(({ relationshipName }) => {
                    return relationshipName === relationshipConfigToDelete.name
                  })
                ) {
                  return {
                    ...acc,
                    [key]: {
                      ...val,
                      relationships: val.relationships.filter((relationship) => {
                        return relationship.relationshipName !== relationshipConfigToDelete.name
                      }),
                    },
                  }
                } else {
                  return {
                    ...acc,
                    [key]: val,
                  }
                }
              }, {}),
            },
          }
        } else {
          return state
        }
      }
      case NEW_RELATIONSHIP_TYPE: {
        const currentRelationshipConfiguration =
          state[action.familyTreeId].relationshipConfiguration
        const relationshipName = action.relationshipName
        return {
          ...state,
          [action.familyTreeId]: {
            ...state[action.familyTreeId],
            relationshipConfiguration: {
              ...currentRelationshipConfiguration,
              [relationshipName]: {
                colour: 'black',
                name: relationshipName,
                type: 'child',
              },
            },
          },
        }
      }
      case NEW_FAMILY_TREE: {
        const maxId = Object.keys(state).reduce((max, next) => {
          if (typeof max === 'number') {
            const id = safeParseInt(next)
            if (id) {
              return Math.max(max, id)
            } else {
              return max
            }
          } else {
            return safeParseInt(next)
          }
        }, null)
        const id = typeof maxId === 'number' ? maxId + 1 : 0
        return {
          ...state,
          [id]: {
            ...cloneDeep(emptyFamilyTree),
            id,
          },
        }
      }
      case DELETE_FAMILY_TREE: {
        if (typeof action.id === 'number' && state[action.id]) {
          return omit(state, action.id)
        } else {
          return state
        }
      }
      case EDIT_FAMILY_TREE_NAME: {
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            name: action.name,
          },
        }
      }
      case DELETE_CHARACTER: {
        return Object.entries(state).reduce((acc, next) => {
          const [treeId, tree] = next
          const withoutCharacter = omit(tree.members, action.id)
          const withoutRelationshipsreferencingCharacter = Object.entries(withoutCharacter).reduce(
            (memberAcc, nextMember) => {
              const [characterId, member] = nextMember
              return {
                ...memberAcc,
                [characterId]: {
                  ...member,
                  relationships: member.relationships
                    .filter((relationship) => {
                      return relationship.characterId !== action.id
                    })
                    .map((relationship) => {
                      return {
                        ...relationship,
                        ...(relationship.sharedSources
                          ? {
                              sharedSources: relationship.sharedSources.filter(
                                (sharedCharacterId) => {
                                  return sharedCharacterId !== action.id
                                }
                              ),
                            }
                          : {}),
                      }
                    }),
                },
              }
            },
            {}
          )
          return {
            ...acc,
            [treeId]: {
              ...tree,
              members: withoutRelationshipsreferencingCharacter,
            },
          }
        }, {})
      }
      case ACTION_CONFLICT_CHANGES: {
        return addConflictedFamilyTreeEntities(
          action.onlineFile.familyTrees,
          action.localOnly,
          action.changedLocalAndOnline,
          action.idMapping
        )
      }
      case FILE_LOADED: {
        return action.data.familyTrees ?? INITIAL_STATE
      }
      case NEW_FILE: {
        return newFileFamilyTrees
      }
      case LOAD_FAMILY_TREES: {
        return action.familyTrees
      }
      case LOAD_FAMILY_TREE: {
        return {
          ...state,
          [action.familyTree.id]: action.familyTree,
        }
      }
      case BATCH_LOAD_FAMILY_TREE: {
        return action.familyTrees.reduce((allFamilyTrees, nextFamilyTree) => {
          return {
            ...allFamilyTrees,
            [nextFamilyTree.id]: nextFamilyTree,
          }
        }, state)
      }
      case REMOVE_FAMILY_TREE: {
        return omit(state, action.familyTree.id)
      }
      case UNDO_N_TIMES:
      case REDO_N_TIMES:
      case UNDO:
      case REDO: {
        if (Array.isArray(action.state.notes)) {
          return action.state.notes
        } else {
          return state
        }
      }
      default: {
        return state
      }
    }
  }

export default familyTreeReducer
