import { createSelector } from 'reselect'

import { createDeepEqualSelector } from './createDeepEqualSelector'
import { allFamilyTreesSelector } from './familyTreeFirstOrder'
import { currentFamilyTreeIdSelector } from './secondOrder'

const _currentFamilyTreeRelationshipConfigurationSelector = createSelector(
  allFamilyTreesSelector,
  currentFamilyTreeIdSelector,
  (allFamilyTrees, currentFamilyTreeId) => {
    if (typeof currentFamilyTreeId === 'number' && allFamilyTrees[currentFamilyTreeId]) {
      return allFamilyTrees[currentFamilyTreeId]?.relationshipConfiguration || null
    } else {
      return null
    }
  }
)

export const currentFamilyTreeRelationshipConfigurationSelector = createDeepEqualSelector(
  _currentFamilyTreeRelationshipConfigurationSelector,
  (currentRelationshipConfiguration) => {
    return currentRelationshipConfiguration
  }
)

const _currentFamilyTreeMembersSelector = createSelector(
  allFamilyTreesSelector,
  currentFamilyTreeIdSelector,
  (allFamilyTrees, currentFamilyTreeId) => {
    if (typeof currentFamilyTreeId === 'number' && allFamilyTrees[currentFamilyTreeId]) {
      return allFamilyTrees[currentFamilyTreeId].members
    } else {
      return null
    }
  }
)

export const currentFamilyTreeMembersSelector = createDeepEqualSelector(
  _currentFamilyTreeMembersSelector,
  (members) => {
    return members
  }
)
