import { identity } from 'lodash'

import selectors from '../selectors'
import {
  DELETE_SELECTED_RELATIONSHIP,
  NEW_RELATIONSHIP_TYPE,
  SET_SELECTED_RELATIONSHIP_TYPE,
  SET_SELECTED_RELATIONSHIP_NAME,
  SET_RELATIONSHIP_TYPE_COLOUR,
  SAVE_FAMILY_TREE,
  NEW_FAMILY_TREE,
  DELETE_FAMILY_TREE,
  EDIT_FAMILY_TREE_NAME,
  LOAD_FAMILY_TREES,
  LOAD_FAMILY_TREE,
  BATCH_LOAD_FAMILY_TREE,
  REMOVE_FAMILY_TREE,
} from '../constants/ActionTypes'

const {
  selectedRelationshipTypeSelector,
  currentFamilyTreeRelationshipConfigurationSelector,
  currentFamilyTreeIdSelector,
} = selectors(identity)

export const saveFamilyTree = (familyTree) => {
  return {
    type: SAVE_FAMILY_TREE,
    familyTree,
  }
}

export const chooseRelationshipTypeColour = (colour) => (dispatch, getState) => {
  const currentFamilyTreeId = currentFamilyTreeIdSelector(getState())
  const selectedRelationship = selectedRelationshipTypeSelector(getState())
  dispatch({
    type: SET_RELATIONSHIP_TYPE_COLOUR,
    familyTreeId: currentFamilyTreeId,
    colour,
    selectedRelationship,
  })
}

export const setSelectedRelationshipName = (name) => (dispatch, getState) => {
  const currentFamilyTreeId = currentFamilyTreeIdSelector(getState())
  const selectedRelationship = selectedRelationshipTypeSelector(getState())
  dispatch({
    type: SET_SELECTED_RELATIONSHIP_NAME,
    familyTreeId: currentFamilyTreeId,
    name,
    selectedRelationship,
  })
}

export const setSelectedRelationshipType = (type) => (dispatch, getState) => {
  const currentFamilyTreeId = currentFamilyTreeIdSelector(getState())
  const selectedRelationship = selectedRelationshipTypeSelector(getState())
  dispatch({
    type: SET_SELECTED_RELATIONSHIP_TYPE,
    familyTreeId: currentFamilyTreeId,
    relationshipType: type,
    selectedRelationship,
  })
}

export const deleteSelectedRelationship = () => (dispatch, getState) => {
  const currentFamilyTreeId = currentFamilyTreeIdSelector(getState())
  const selectedRelationship = selectedRelationshipTypeSelector(getState())
  const currentRelationshipConfiguration = currentFamilyTreeRelationshipConfigurationSelector(
    getState()
  )
  const newSelectedRelationshipName =
    Object.values(currentRelationshipConfiguration).find((relationshipType) => {
      return relationshipType.name !== selectedRelationship
    })?.name || null
  dispatch({
    type: DELETE_SELECTED_RELATIONSHIP,
    familyTreeId: currentFamilyTreeId,
    selectedRelationship,
    newSelectedRelationshipName,
  })
}

export const newRelationshipType = () => (dispatch, getState) => {
  const currentFamilyTreeId = currentFamilyTreeIdSelector(getState())
  const currentRelationshipConfiguration = currentFamilyTreeRelationshipConfigurationSelector(
    getState()
  )
  let i = null
  if (currentRelationshipConfiguration[`New Relationship`]) {
    i = 1
    while (currentRelationshipConfiguration[`New Relationship ${i}`]) {
      i++
      // Prevent infinite loops by limiting the number of New Relationship N's to 100
      if (i > 100) {
        return
      }
    }
  }
  const relationshipName = i === null ? 'New Relationship' : `New Relationship ${i}`
  dispatch({
    type: NEW_RELATIONSHIP_TYPE,
    familyTreeId: currentFamilyTreeId,
    relationshipName,
  })
}

export const deleteFamilyTreeById = (id) => {
  return {
    type: DELETE_FAMILY_TREE,
    id,
  }
}

export const newFamilyTree = () => {
  return {
    type: NEW_FAMILY_TREE,
  }
}

export const editFamilyTreeName = (id, name) => {
  return {
    type: EDIT_FAMILY_TREE_NAME,
    id,
    name,
  }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function load(patching, familyTrees) {
  return { type: LOAD_FAMILY_TREES, patching, familyTrees }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function loadSingle(patching, familyTree) {
  return { type: LOAD_FAMILY_TREE, patching, familyTree }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function batchLoad(patching, familyTrees) {
  return { type: BATCH_LOAD_FAMILY_TREE, patching, familyTrees }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function removeSingle(patching, familyTree) {
  return { type: REMOVE_FAMILY_TREE, patching, familyTree }
}
