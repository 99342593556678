import { union, sortBy, uniq, identity, isEmpty, mapValues, omit, keyBy, isEqual } from 'lodash'

import {
  SELECT_FILE,
  SELECT_EMPTY_FILE,
  SET_FILE_LIST,
  SHOW_LOADER,
  UNSET_FILE_LOADED,
  SET_OFFLINE,
  SET_RESUMING,
  SET_CHECKING_FOR_OFFLINE_DRIFT,
  SET_OVERWRITING_CLOUD_WITH_BACKUP,
  SET_SHOW_RESUME_MESSAGE_DIALOG,
  SET_BACKING_UP_OFFLINE_FILE,
  START_CREATING_NEW_PROJECT,
  FINISH_CREATING_NEW_PROJECT,
  ADD_BOOK_FROM_PLTR,
  CLOSE_IMPORT_PLOTTR_MODAL,
  ADD_CHARACTER_FROM_PLTR,
  ADD_PLACE_FROM_PLTR,
  ADD_NOTE_FROM_PLTR,
  ADD_TAG_FROM_PLTR,
  ADD_IMAGE_FROM_PLTR,
  SET_CONFLICT_RESOLUTION_DATA,
  SET_CONFLICT_RESOLUTION_ITEM_ACTION,
  ACTION_CONFLICT_CHANGES,
  BOOTED_OFFLINE_FILE_WHILST_ONLINE,
  LOAD_FILE,
  LOAD_UI,
  CLOSE_CONFLICT_RESOLUTION_MODAL,
} from '../constants/ActionTypes'
import selectors from '../selectors'
import { addCardAttr, addNoteAttr, addPlaceAttr } from './customAttributes'
import { imageId, nextId } from '../store/newIds'
import { adjustIds, newTree } from '../reducers/tree'
import { removeExistingAttributes } from '../helpers/customAttributes'
import { nextId as nextBeatId } from '../helpers/nextBeatId'

/**
 * @typedef {import('../reducers/familyTree').Family} Family
 */

const {
  importModalBookDataSelector,
  importPltrDataSelector,
  imagesSelector,
  allCharactersSelector,
  allPlacesSelector,
  allTagsSelector,
  allBooksWithAllAssociatedDataSelector,
  allNotesSelector,
  singleTagSelector,
  singleCharacterSelector,
  singlePlaceSelector,
  singleBookSelector,
  localOnlyConflictChangesSelector,
  localAndOnlineConflictChangesSelector,
  seriesConflictSelector,
  onlineFileForConflictResolutionSelector,
  attributesSelector,
  allCustomAttributesSelector,
  conflictResolutionCustomAttributesSelector,
  characterAttributesForBookSelector,
  allCategoriesSelector,
  allBeatsSelector,
  nextLineIdSelector,
  nextCardIdSelector,
  allBooksAsArraySelector,
  seriesSelector,
  fullFileStateSelector,
  cardsCustomAttributesSelector,
  placeCustomAttributesSelector,
  noteCustomAttributesSelector,
  fileIdSelector,
  clientIdSelector,
  allFamilyTreesSelector,
} = selectors(identity)

export const withFullFileState = (cb) => (dispatch, getState) => {
  cb(getState())
}

export const selectFile = (permission, fileURL) => ({
  type: SELECT_FILE,
  permission,
  fileURL,
})

export const selectEmptyFile = () => ({
  type: SELECT_EMPTY_FILE,
})

export const unsetFileLoaded = () => ({
  type: UNSET_FILE_LOADED,
})

export const showLoader = (isLoading) => ({
  type: SHOW_LOADER,
  isLoading,
})

export const setOffline = (isOffline) => {
  return {
    type: SET_OFFLINE,
    isOffline,
  }
}

export const setResuming = (resuming) => ({
  type: SET_RESUMING,
  resuming,
})

export const setCheckingForOfflineDrift = (checkingOfflineDrift) => ({
  type: SET_CHECKING_FOR_OFFLINE_DRIFT,
  checkingOfflineDrift,
})

export const setOverwritingCloudWithBackup = (overwritingCloudWithBackup) => ({
  type: SET_OVERWRITING_CLOUD_WITH_BACKUP,
  overwritingCloudWithBackup,
})

export const setShowResumeMessageDialog = (showResumeMessageDialog) => ({
  type: SET_SHOW_RESUME_MESSAGE_DIALOG,
  showResumeMessageDialog,
})

export const setBackingUpOfflineFile = (backingUpOfflineFile) => ({
  type: SET_BACKING_UP_OFFLINE_FILE,
  backingUpOfflineFile,
})

export const startCreatingNewProject = (template, defaultName) => ({
  type: START_CREATING_NEW_PROJECT,
  template,
  defaultName,
})

export const finishCreatingNewProject = () => ({
  type: FINISH_CREATING_NEW_PROJECT,
})

export const saveImportPltrData = () => (dispatch, getState) => {
  const state = getState()
  const importData = importPltrDataSelector(state)
  const bookData = importModalBookDataSelector(state)
  const currentCharacters = allCharactersSelector(state)
  const currentPlaces = allPlacesSelector(state)
  const currentTags = allTagsSelector(state)
  const books = importData['books']
  const hierarchyLevels = bookData['hierarchyLevels']
  const beats = bookData['beats']
  const cards = bookData['cards']
  const lines = bookData['lines']
  const customAttributes = importData['customAttributes']
  const notes = importData['notes']
  const characters = importData['characters']
  const places = importData['places']
  const tags = importData['tags']
  const images = importData['images']

  const cardCustomAttr = cardsCustomAttributesSelector(state)
  const placesCustomAttr = placeCustomAttributesSelector(state)
  const notesCustomAttr = noteCustomAttributesSelector(state)

  const booksWithAllIds = { ...books, allIds: bookData['allIds'] }
  const allImages = imagesSelector(state)
  const newImageId = imageId(allImages)
  const newCharacterId = nextId(currentCharacters)
  const newPlaceId = nextId(currentPlaces)
  const newTagId = nextId(currentTags)

  const newImages = Object.values(images)
    .map((image, idx) => {
      const newId = newImageId + idx
      const hasBookImageToSave = [...Object.values(omit(books, 'allIds'))].some(
        (i) => i.isChecked && String(i.imageId) == String(image.id)
      )
      const hasCharacterImageToSave = characters.some(
        (i) => i.isChecked && String(i.imageId) == String(image.id)
      )
      const hasNoteImageToSave = notes.some(
        (i) => i.isChecked && String(i.imageId) == String(image.id)
      )
      const hasPlaceImageToSave = places.some(
        (i) => i.isChecked && String(i.imageId) == String(image.id)
      )
      if (
        hasBookImageToSave ||
        hasCharacterImageToSave ||
        hasNoteImageToSave ||
        hasPlaceImageToSave
      ) {
        dispatch({
          type: ADD_IMAGE_FROM_PLTR,
          image,
          newId,
        })
        return {
          ...image,
          newId,
        }
      }
    })
    .reduce((acc, item) => {
      if (item) {
        acc[item.id] = item
      }
      return acc
    }, {})

  const newCharacters = characters
    .map((character, idx) => {
      if (character.isChecked) {
        const newId = newCharacterId + idx
        const newCharacter = {
          ...character,
          id: newId,
          bookIds: [],
          tags: [],
          attributes: [],
          noteIds: [],
          places: [],
          cards: [],
          templates: [],
          categoryId: null,
          imageId:
            character.imageId && newImages[character.imageId]?.newId
              ? String(newImages[character.imageId]?.newId)
              : null,
        }
        dispatch({
          type: ADD_CHARACTER_FROM_PLTR,
          character: newCharacter,
        })

        return {
          ...newCharacter,
          newId,
        }
      }
    })
    .reduce((acc, obj) => {
      if (obj) {
        acc[obj.id] = obj
      }
      return acc
    }, {})

  const newPlaces = places
    .map((place, idx) => {
      if (place.isChecked) {
        const newId = newPlaceId + idx
        const newPlace = {
          ...place,
          id: newId,
          bookIds: [],
          tags: [],
          noteIds: [],
          characters: [],
          templates: [],
          cards: [],
          categoryId: null,
          imageId:
            place.imageId && newImages[place.imageId]?.newId
              ? String(newImages[place.imageId]?.newId)
              : null,
        }
        dispatch({
          type: ADD_PLACE_FROM_PLTR,
          place: newPlace,
        })

        return {
          ...newPlace,
          newId,
        }
      }
    })
    .reduce((acc, obj) => {
      if (obj) {
        acc[obj.id] = obj
      }
      return acc
    }, {})

  const newTags = tags
    .map((tag, idx) => {
      if (tag.isChecked) {
        const newId = newTagId + idx
        const newTag = {
          ...tag,
          categoryId: null,
          id: newId,
        }
        dispatch({
          type: ADD_TAG_FROM_PLTR,
          tag: newTag,
        })

        return {
          ...newTag,
          newId,
        }
      }
    })
    .reduce((acc, obj) => {
      if (obj) {
        acc[obj.id] = obj
      }
      return acc
    }, {})

  notes.forEach((note, _idx) => {
    if (note.isChecked) {
      dispatch({
        type: ADD_NOTE_FROM_PLTR,
        note: {
          ...note,
          tags: [],
          characters: [],
          templates: [],
          cards: [],
          bookIds: [],
          places: [],
          categoryId: null,
          imageId:
            note.imageId && newImages[note.imageId]?.newId
              ? String(newImages[note.imageId].newId)
              : null,
        },
      })
    }
  })

  mapValues(booksWithAllIds, (bookItem, bookId) => {
    if (bookItem.isChecked) {
      dispatch({
        type: ADD_BOOK_FROM_PLTR,
        book: bookItem,
        id: bookId,
        cards: cards.map((card) => {
          // replace old character, place and tag ids
          // inside the cards
          const newCharacterIds = (card.characters || [])
            .map((characterId) => {
              if (String(characterId) === String(newCharacters[characterId]?.id)) {
                return newCharacters[characterId].newId
              } else {
                return null
              }
            })
            .filter(Boolean)
          const newPlaceIds = (card.places || [])
            .map((placeId) => {
              if (String(placeId) === String(newPlaces[placeId]?.id)) {
                return newPlaces[placeId].newId
              } else {
                return null
              }
            })
            .filter(Boolean)
          const newTagIds = (card.tags || [])
            .map((tagId) => {
              if (String(tagId) === String(newTags[tagId]?.id)) {
                return newTags[tagId].newId
              } else {
                return null
              }
            })
            .filter(Boolean)

          return {
            ...card,
            fromTemplateId: null,
            templates: [],
            characters: newCharacterIds,
            places: newPlaceIds,
            tags: newTagIds,
          }
        }),
        lines: lines.filter((l) => l.id && String(l.bookId) === String(bookId)),
        beats: !isEmpty(beats[bookId]) ? beats[bookId] : newTree('id'),
        hierarchyLevels:
          !isEmpty(hierarchyLevels) && !isEmpty(hierarchyLevels[bookId])
            ? hierarchyLevels[bookId]
            : null,
        images: newImages,
      })
    }
  })

  Object.entries(customAttributes).forEach(([sectionName, attributeObject]) => {
    switch (sectionName) {
      case 'scenes': {
        const newAttrs = removeExistingAttributes(attributeObject.attributes, cardCustomAttr)
        newAttrs.forEach((attribute) => {
          addCardAttr(attribute)(dispatch)
        })
        break
      }

      case 'places': {
        const newAttrs = removeExistingAttributes(attributeObject.attributes, placesCustomAttr)
        newAttrs.forEach((attribute) => {
          addPlaceAttr(attribute)(dispatch)
        })
        break
      }

      case 'notes': {
        const newAttrs = removeExistingAttributes(attributeObject.attributes, notesCustomAttr)
        newAttrs.forEach((attribute) => {
          addNoteAttr(attribute)(dispatch)
        })
        break
      }
    }
  })

  dispatch({
    type: CLOSE_IMPORT_PLOTTR_MODAL,
  })
}

const idsAreEqual = (x) => (y) => {
  return x.id === y.id
}

const ATTRIBUTES_TO_STRIP_DURING_DIFF = ['clientId', 'fileURL', 'fileId', 'isCloudFile']

const stripNonDiffableDataFromBookBundle = (bookBundle) => {
  const omitNonDiffableData = (x) => omit(x, ATTRIBUTES_TO_STRIP_DURING_DIFF)
  return {
    book: bookBundle.book,
    lines: bookBundle.lines.map(omitNonDiffableData),
    cards: bookBundle.cards.map(omitNonDiffableData),
    beats: omitNonDiffableData(bookBundle.beats),
    hierarchy: omitNonDiffableData(bookBundle.hierarchy),
  }
}

export const bookDiff = (offlineBooks, onlineBooks, onlineFile) => {
  const startingBeatId = nextBeatId(allBeatsSelector(onlineFile))
  const startingLineId = nextLineIdSelector(onlineFile)
  const startingCardId = nextCardIdSelector(onlineFile)

  // NOTE: this state outlives the `bumpBookKeys` function because we
  // need to adjust the ids of books that are local-only and those
  // that were changed both online and offline.
  let currentBeatId = startingBeatId
  // Old id -> new
  const beatIdMap = {}
  let currentLineId = startingLineId
  // Old id -> new
  const lineIdMap = {}
  let currentCardId = startingCardId
  // Old id -> new
  const cardIdMap = {}
  const bumpBookKeys = (book) => {
    const bumpArrayIds = (entities, getNextId, idMap) => {
      return entities.map((entity) => {
        const newId = getNextId()
        idMap[entity.id] = newId
        return {
          ...entity,
          id: newId,
        }
      })
    }

    const bumpBeatIds = (beats) => {
      const adjustId = (id) => {
        if (beatIdMap[id]) {
          return beatIdMap[id]
        } else if (id === null || id === 'null') {
          return null
        } else {
          const newId = currentBeatId++
          beatIdMap[id] = newId
          return newId
        }
      }

      return adjustIds('id')(beats, adjustId)
    }

    const newBeats = bumpBeatIds(book.beats)
    const newLines = bumpArrayIds(
      book.lines,
      () => {
        return currentLineId++
      },
      lineIdMap
    )
    const newCards = bumpArrayIds(
      book.cards,
      () => {
        return currentCardId++
      },
      cardIdMap
    ).map((card) => {
      return {
        ...card,
        lineId: lineIdMap[card.lineId] ?? card.lineId,
        beatId: beatIdMap[card.beatId] ?? card.beatId,
      }
    })
    return {
      ...book,
      lines: newLines,
      cards: newCards,
      beats: newBeats,
    }
  }

  const localOnly = offlineBooks
    .filter(({ book }) => {
      return !onlineBooks.some((onlineBook) => {
        return onlineBook.book.id === book.id
      })
    })
    .map(bumpBookKeys)

  const onlineAndOffline = onlineBooks.filter((onlineBook) => {
    const onlineWithoutNonDiffData = stripNonDiffableDataFromBookBundle(onlineBook)
    const offlineBook = offlineBooks.find(({ book }) => {
      return book.id === onlineBook.book.id
    })
    return (
      offlineBook &&
      !isEqualTreatingUndefinedOrMissingAsTheSame(
        stripNonDiffableDataFromBookBundle(offlineBook),
        onlineWithoutNonDiffData
      )
    )
  })

  return [
    localOnly,
    onlineAndOffline.map((onlineBook) => {
      return {
        onlineEntity: onlineBook,
        offlineEntity: bumpBookKeys(
          offlineBooks.find((offlineBook) => {
            return offlineBook.book.id === onlineBook.book.id
          })
        ),
      }
    }),
  ]
}

export const isEqualTreatingUndefinedOrMissingAsTheSame = (one, other) => {
  if (typeof one !== typeof other) {
    return false
  } else if (Array.isArray(one) && Array.isArray(other)) {
    return (
      one.length === other.length &&
      one.every((x, index) => {
        return isEqualTreatingUndefinedOrMissingAsTheSame(x, other[index])
      })
    )
  } else if (one === null) {
    return other === null
  } else if (typeof one === 'undefined') {
    return typeof other === 'undefined'
  } else if (typeof one === 'object') {
    return (
      Object.entries(one).reduce((acc, next) => {
        const [key, value] = next
        return (
          acc &&
          ((typeof value === 'undefined' && typeof other[key] === 'undefined') ||
            isEqualTreatingUndefinedOrMissingAsTheSame(value, other[key]))
        )
      }, true) &&
      Object.entries(other).reduce((acc, next) => {
        const [key, value] = next
        return (
          acc &&
          ((typeof value === 'undefined' && typeof one[key] === 'undefined') ||
            isEqualTreatingUndefinedOrMissingAsTheSame(value, one[key]))
        )
      }, true)
    )
  } else {
    return one === other
  }
}

/**
 * @template A
 * @typedef LocalAndOnline
 * @property {A} onlineEntity
 * @property {A | undefined} offlineEntity
 */

/**
 * @template A
 * @typedef {[Array<A>, Array<LocalAndOnline<A>>]} EntityDiffs
 */

/**
 * Produce a two-tuple of the entities which are only on the local
 * version of the file and those which are both online and offline but
 * differ between the two.
 *
 * @template A
 * @param {Array<A>} offlineEntities
 * @param {Array<A>} onlineEntities
 * @return {EntityDiffs<A>}
 */
export const arrayEntityDiff = (offlineEntities, onlineEntities) => {
  const localOnly = offlineEntities.filter((offlineEntity) => {
    return !onlineEntities.some(idsAreEqual(offlineEntity))
  })
  const onlineAndOffline = onlineEntities.filter((onlineEntity) => {
    const onlineWithoutNonDiffData = omit(
      // TS compiler and lodash sometimes don't play together very well.
      // @ts-ignore
      onlineEntity,
      ATTRIBUTES_TO_STRIP_DURING_DIFF
    )
    const offlineEntity = offlineEntities.find(idsAreEqual(onlineEntity))
    return (
      offlineEntity &&
      !isEqualTreatingUndefinedOrMissingAsTheSame(
        omit(
          // TS compiler and lodash sometimes don't play together very well.
          // @ts-ignore
          offlineEntity,
          ATTRIBUTES_TO_STRIP_DURING_DIFF
        ),
        onlineWithoutNonDiffData
      )
    )
  })
  return [
    localOnly,
    onlineAndOffline.map((onlineEntity) => {
      return {
        onlineEntity: onlineEntity,
        offlineEntity: offlineEntities.find(idsAreEqual(onlineEntity)),
      }
    }),
  ]
}

/**
 * @template A
 * @typedef ActionableData
 * @property {A} data
 * @property {'INCLUDE'|'DUPLICATE'} action
 */

/**
 * @template A
 * @param {A} data
 * @return {ActionableData<A>}
 */
export const defaultLocalAction = (data) => {
  return {
    data,
    action: 'INCLUDE',
  }
}

/**
 * @template A
 * @param {A} data
 * @return {ActionableData<A>}
 */
export const defaultLocalAndOnlineAction = (data) => {
  return {
    data,
    action: 'DUPLICATE',
  }
}

export const mergeAttributeType = (onlineAttributes, offlineAttributes) => {
  let maxId = onlineAttributes.reduce((acc, { id }) => {
    if (typeof id === 'number') {
      return Math.max(acc, id)
    } else {
      return id
    }
  }, 0)
  // Offline entities might have their attribute ids shifted by the
  // merge.
  const conflictedAttributeMapping = {}
  const mergedAttributes = Object.values(
    onlineAttributes.concat(offlineAttributes).reduce((acc, next) => {
      const conflict = () => {
        maxId++
        conflictedAttributeMapping[next.id] = maxId
        return {
          ...acc,
          [maxId]: {
            ...next,
            id: maxId,
          },
        }
      }
      const existingAttribute = acc[next.id]
      if (typeof existingAttribute === 'object') {
        if (existingAttribute.name === next.name) {
          if (
            (existingAttribute.type === 'base-attribute' || next.type === 'base-attribute') &&
            existingAttribute.type !== next.type
          ) {
            return conflict()
          } else if (existingAttribute.type !== next.type && next.type === 'paragraph') {
            return {
              ...acc,
              [next.id]: next,
            }
          } else {
            return acc
          }
        } else {
          return conflict()
        }
      } else {
        return {
          ...acc,
          [next.id]: next,
        }
      }
    }, {})
  )
  return {
    conflictedAttributeMapping,
    mergedAttributes,
  }
}

export const mergeAttributes = (allOnlineAttributes, allOfflineAttributes) => {
  return Object.keys(allOnlineAttributes).reduce((acc, name) => {
    return {
      ...acc,
      [name]: mergeAttributeType(allOnlineAttributes[name], allOfflineAttributes[name]),
    }
  }, {})
}

export const mergeCustomAttributeType = (localCustomAttributes, remoteCustomAttirbutes) => {
  return Object.values(
    localCustomAttributes.concat(remoteCustomAttirbutes).reduce((acc, next) => {
      const { name, type } = next
      const existingAttribute = acc[name]
      if (typeof existingAttribute === 'object') {
        if (type === 'paragraph') {
          return {
            ...acc,
            [name]: next,
          }
        } else {
          return acc
        }
      } else {
        return {
          ...acc,
          [name]: next,
        }
      }
    }, {})
  )
}

export const mergeCustomAttributes = (allLocalCustomAttributes, allRemoteCustomAttirbutes) => {
  return Object.keys(allLocalCustomAttributes).reduce((acc, name) => {
    return {
      ...acc,
      [name]: mergeCustomAttributeType(
        allLocalCustomAttributes[name] ?? [],
        allRemoteCustomAttirbutes[name] ?? []
      ),
    }
  }, {})
}

export const mergeCategoryType = (onlineCategories, offlineCategories) => {
  let maxId = onlineCategories.reduce((acc, { id }) => {
    if (typeof id === 'number') {
      return Math.max(acc, id)
    } else {
      return id
    }
  }, 0)
  let maxPosition = onlineCategories.reduce((acc, { position }) => {
    if (typeof position === 'number') {
      return Math.max(acc, position)
    } else {
      return position
    }
  }, 0)
  // Offline entities might have their category ids shifted by the
  // merge.
  const conflictedCategoryMapping = {}
  const mergedCategories = Object.values(
    offlineCategories.reduce((acc, next) => {
      const conflict = () => {
        maxId++
        conflictedCategoryMapping[next.id] = maxId
        return {
          ...acc,
          [maxId]: {
            ...next,
            id: maxId,
          },
        }
      }
      const bumpPosition = (categories, id) => {
        maxPosition++
        return {
          ...categories,
          [id]: {
            ...categories[id],
            position: maxPosition,
          },
        }
      }
      const existingCategory = acc[next.id]
      if (typeof existingCategory === 'object') {
        if (existingCategory.name === next.name) {
          return acc
        } else if (next.position <= maxPosition) {
          // Beware the side effect that `conflict` has on `maxId`!!
          const withConflictResolved = conflict()
          return bumpPosition(withConflictResolved, maxId)
        } else {
          return conflict()
        }
      } else if (next.position <= maxPosition) {
        return bumpPosition(
          {
            ...acc,
            [next.id]: next,
          },
          next.id
        )
      } else {
        return {
          ...acc,
          [next.id]: next,
        }
      }
    }, keyBy(onlineCategories, 'id'))
  )
  return {
    conflictedCategoryMapping,
    mergedCategories,
  }
}

export const mergeCategories = (allOnlineCategories, allOfflineCategories) => {
  return Object.keys(allOnlineCategories).reduce((acc, name) => {
    return {
      ...acc,
      [name]: mergeCategoryType(allOnlineCategories[name], allOfflineCategories[name]),
    }
  }, {})
}

const seriesDiff = (onlineSeries, offlineSeries) => {
  return !isEqualTreatingUndefinedOrMissingAsTheSame(onlineSeries, offlineSeries)
    ? { onlineSeries, offlineSeries }
    : null
}

/**
 * @param {Record<String, Family>} onlineFamilyTrees
 * @param {Record<String, Family>} offlineFamilyTrees
 * @return {EntityDiffs<Family>}
 */
const familyTreeDiff = (onlineFamilyTrees, offlineFamilyTrees) => {
  const allIds = union(Object.keys(onlineFamilyTrees), Object.keys(offlineFamilyTrees))
  return allIds.reduce(
    (acc, id) => {
      if (ATTRIBUTES_TO_STRIP_DURING_DIFF.includes(id)) {
        return acc
      } else {
        const onlineFamilyTree = onlineFamilyTrees[id]
        const offlineFamilyTree = offlineFamilyTrees[id]
        if (!onlineFamilyTree) {
          if (offlineFamilyTree) {
            return [[...acc[0], offlineFamilyTree], acc[1]]
          } else {
            return acc
          }
        } else if (
          onlineFamilyTree &&
          offlineFamilyTree &&
          !isEqual(onlineFamilyTree, offlineFamilyTree)
        ) {
          return [
            acc[0],
            [...acc[1], { onlineEntity: onlineFamilyTree, offlineEntity: offlineFamilyTree }],
          ]
        } else {
          return acc
        }
      }
    },
    [[], []]
  )
}

const ARRAY_KEYS = ['cards', 'characters', 'lines', 'notes', 'places', 'tags']

const omitNonDiffableData = (entity) => {
  return omit(entity, ATTRIBUTES_TO_STRIP_DURING_DIFF)
}

export const diffPerKey = (offlineFile, onlineFile) => {
  return Object.keys(offlineFile)
    .map((key) => {
      if (ARRAY_KEYS.includes(key)) {
        if (
          !isEqualTreatingUndefinedOrMissingAsTheSame(
            sortBy(offlineFile[key].map(omitNonDiffableData), 'id'),
            sortBy(onlineFile[key].map(omitNonDiffableData), 'id').slice(0, offlineFile[key].length)
          )
        ) {
          return key
        } else {
          return null
        }
      } else if (!isEqualTreatingUndefinedOrMissingAsTheSame(offlineFile[key], onlineFile[key])) {
        return key
      } else {
        return null
      }
    })
    .filter((x) => {
      return typeof x === 'string'
    })
}

export const computeAndSetResumeData =
  (onlineFile, overwriteAllKeys, retryWithBackOff, logger) => (dispatch, getState) => {
    const onlineState = { user: onlineFile }
    const state = getState()
    const keysThatDiffer = diffPerKey(fullFileStateSelector(state), onlineFile)

    const onlyUIOrFileDiffer = keysThatDiffer.every((key) => {
      return ['ui', 'file'].includes(key)
    })
    if (onlyUIOrFileDiffer) {
      // Don't try to resolve differences in the ui or file keys.
      // Instead, load up the online versions.
      dispatch({
        file: onlineFile.file,
        type: LOAD_FILE,
      })
      dispatch({
        ui: onlineFile.ui,
        type: LOAD_UI,
      })
      dispatch(setResuming(false))
    } else {
      const mergedCustomAttributes = mergeCustomAttributes(
        allCustomAttributesSelector(state),
        // @ts-ignore
        allCustomAttributesSelector(onlineState)
      )
      const [localOnlyNotes, localAndOnlineNotes] = arrayEntityDiff(
        allNotesSelector(state),
        // @ts-ignore
        allNotesSelector(onlineState)
      )
      const [localOnlyCharacters, localAndOnlineCharacters] = arrayEntityDiff(
        allCharactersSelector(state),
        // @ts-ignore
        allCharactersSelector(onlineState)
      )
      const [localOnlyPlaces, localAndOnlinePlaces] = arrayEntityDiff(
        allPlacesSelector(state),
        // @ts-ignore
        allPlacesSelector(onlineState)
      )
      const [localOnlyTags, localAndOnlineTags] = arrayEntityDiff(
        allTagsSelector(state),
        // @ts-ignore
        allTagsSelector(onlineState)
      )
      const [localOnlyBooks, localAndOnlineBooks] = bookDiff(
        allBooksWithAllAssociatedDataSelector(state),
        // @ts-ignore
        allBooksWithAllAssociatedDataSelector(onlineState),
        onlineState
      )
      const seriesEntitiesOrNull = seriesDiff(
        // @ts-ignore
        seriesSelector(onlineState),
        seriesSelector(state)
      )
      const [localOnlyFamilyTrees, localAndOnlineFamilyTrees] = familyTreeDiff(
        // @ts-ignore
        allFamilyTreesSelector(onlineState),
        allFamilyTreesSelector(state)
      )

      dispatch({
        type: SET_CONFLICT_RESOLUTION_DATA,
        onlineFile,
        localOnly: {
          notes: localOnlyNotes.map(defaultLocalAction),
          characters: localOnlyCharacters.map(defaultLocalAction),
          places: localOnlyPlaces.map(defaultLocalAction),
          tags: localOnlyTags.map(defaultLocalAction),
          books: localOnlyBooks.map(defaultLocalAction),
          familyTrees: localOnlyFamilyTrees.map(defaultLocalAction),
        },
        changedLocalAndOnline: {
          notes: localAndOnlineNotes.map(defaultLocalAndOnlineAction),
          characters: localAndOnlineCharacters.map(defaultLocalAndOnlineAction),
          places: localAndOnlinePlaces.map(defaultLocalAndOnlineAction),
          tags: localAndOnlineTags.map(defaultLocalAndOnlineAction),
          books: localAndOnlineBooks.map(defaultLocalAndOnlineAction),
          familyTrees: localAndOnlineFamilyTrees.map(defaultLocalAndOnlineAction),
        },
        customAttributes: mergedCustomAttributes,
        seriesDiff: seriesEntitiesOrNull
          ? {
              action: 'OVERWRITE',
              data: seriesEntitiesOrNull,
            }
          : null,
      })
      const onlyAttributesCategoriesAndCustomAttributesDiffer =
        !onlyUIOrFileDiffer &&
        keysThatDiffer.every((key) => {
          return ['ui', 'file', 'customAttributes', 'attributes', 'categories'].includes(key)
        })
      // If there are differences that the user has no say in resolving.
      // Simply resolve them.
      if (onlyAttributesCategoriesAndCustomAttributesDiffer) {
        dispatch(actionConflictChanges())
        dispatch(setOverwritingCloudWithBackup(true))
        const fileId = fileIdSelector(getState())
        const clientId = clientIdSelector(getState())
        const withoutSystemKeys = fullFileStateSelector(getState())
        retryWithBackOff(() => {
          return overwriteAllKeys(fileId, clientId, {
            ...withoutSystemKeys,
            file: {
              ...withoutSystemKeys.file,
              fileName: withoutSystemKeys.file.originalFileName ?? withoutSystemKeys.file.fileName,
            },
          })
        }, logger).then(() => {
          dispatch(setOverwritingCloudWithBackup(false))
          dispatch(setResuming(false))
          dispatch({
            type: CLOSE_CONFLICT_RESOLUTION_MODAL,
          })
        })
      }
    }
  }

export const setConflictResolutionAction = (location, type, id, action) => {
  return {
    type: SET_CONFLICT_RESOLUTION_ITEM_ACTION,
    location,
    entityType: type,
    id,
    action,
  }
}

const idEquals = (id) => (entity) => {
  return entity?.id === id
}

export const withMissingEntitiesOfType = (rawAlreadyMissing, type, entity, state, localOnly) => {
  const alreadyMissing = {
    customAttributes: Object.assign(
      {},
      {
        tags: [],
        characters: [],
        places: [],
        notes: [],
        books: [],
      },
      rawAlreadyMissing.customAttributes
    ),
    attributes: Object.assign({}, rawAlreadyMissing.attributes),
  }
  const tagsAttributeId = characterAttributesForBookSelector(state).find(({ type, name }) => {
    return type === 'base-attribute' && name === 'tags'
  })?.id
  const missingTags = () => {
    const tagIdIsMissing = (tagId) => {
      return (
        !singleTagSelector(
          state,
          // @ts-ignore
          tagId
        ) && !localOnly.tags.find(idEquals(tagId))
      )
    }
    if (type === 'characters' && typeof tagsAttributeId === 'number') {
      const tagsAttribute = (entity?.attributes ?? []).find(({ id }) => {
        return id === tagsAttributeId
      })
      return { id: tagsAttributeId, values: tagsAttribute?.value?.filter?.(tagIdIsMissing) ?? [] }
    } else {
      return entity.tags.filter(tagIdIsMissing)
    }
  }
  const missingCharacters = () => {
    return entity.characters.filter((characterId) => {
      return (
        !singleCharacterSelector(
          state,
          // @ts-ignore
          characterId
        ) && !localOnly.characters.find(idEquals(characterId))
      )
    })
  }
  const missingPlaces = () => {
    return entity.places.filter((placeId) => {
      return (
        !singlePlaceSelector(
          state,
          // @ts-ignore
          placeId
        ) && !localOnly.places.find(idEquals(placeId))
      )
    })
  }
  const missingBooks = () => {
    return entity.bookIds.filter((bookId) => {
      return (
        !singleBookSelector(
          state,
          // @ts-ignore
          bookId
        ) && !localOnly.books.find(idEquals(bookId))
      )
    })
  }
  switch (type) {
    case 'notes': {
      return {
        ...alreadyMissing,
        customAttributes: {
          ...alreadyMissing.customAttributes,
          books: uniq([...missingBooks(), ...alreadyMissing.customAttributes.books]),
          tags: uniq([...missingTags(), ...alreadyMissing.customAttributes.tags]),
          characters: uniq([...missingCharacters(), ...alreadyMissing.customAttributes.characters]),
          places: uniq([...missingPlaces(), ...alreadyMissing.customAttributes.places]),
        },
      }
    }
    case 'characters': {
      const missingTagsObject = missingTags()
      return {
        ...alreadyMissing,
        customAttributes: {
          ...alreadyMissing.customAttributes,
          books: uniq([...missingBooks(), ...(alreadyMissing?.attributes?.books ?? [])]),
        },
        attributes: {
          ...alreadyMissing.attributes,
          tags:
            missingTagsObject?.values?.length > 0
              ? {
                  values: uniq([
                    ...missingTagsObject.values,
                    ...(alreadyMissing?.attributes?.tags?.values ?? []),
                  ]),
                  id: tagsAttributeId,
                }
              : alreadyMissing.attributes.tags ?? [],
        },
      }
    }
    case 'places': {
      return {
        ...alreadyMissing,
        customAttributes: {
          ...alreadyMissing.customAttributes,
          books: uniq([...missingBooks(), ...alreadyMissing.customAttributes.books]),
          tags: uniq([...missingTags(), ...alreadyMissing.customAttributes.tags]),
        },
      }
    }
    default: {
      return alreadyMissing
    }
  }
}

export const findMissingEntities = (state, localOnly, changedLocalAndOnline) => {
  const withMissingFromLocal = Object.entries(localOnly).reduce(
    (acc, next) => {
      const [type, entries] = next
      return entries.reduce((accWithEntries, { action, data }) => {
        if (action === 'INCLUDE') {
          return withMissingEntitiesOfType(accWithEntries, type, data, state, localOnly)
        } else {
          return accWithEntries
        }
      }, acc)
    },
    {
      customAttributes: {
        tags: [],
        characters: [],
        places: [],
        notes: [],
        books: [],
      },
      attributes: {},
    }
  )
  const withMissingFromConflicts = Object.entries(changedLocalAndOnline).reduce((acc, next) => {
    const [type, entries] = next
    return entries.reduce((accWithEntries, { data }) => {
      const offlineEntity = data.offlineEntity
      return withMissingEntitiesOfType(accWithEntries, type, offlineEntity, state, localOnly)
    }, acc)
  }, withMissingFromLocal)
  return withMissingFromConflicts
}

export const bumpBookIds = (onlineFile, localOnlyBooks, changedLocalAndOnlineBooks) => {
  let currentBookId = nextId(allBooksAsArraySelector(onlineFile))
  const localOnlyWithCorrectedBookIds = localOnlyBooks.reduce((acc, next) => {
    const {
      data: { book, lines, cards, beats, hierarchy },
      action,
    } = next
    if (action === 'INCLUDE') {
      const newBookId = currentBookId
      currentBookId++
      return [
        ...acc,
        {
          data: {
            book: {
              ...book,
              id: newBookId,
            },
            lines: lines.map((line) => {
              return {
                ...line,
                bookId: newBookId,
              }
            }),
            cards,
            beats: {
              ...beats,
              index: Object.values(beats.index).reduce((newIndex, nextBeat) => {
                return {
                  [nextBeat.id]: {
                    ...nextBeat,
                    bookId: newBookId,
                  },
                  ...newIndex,
                }
              }, {}),
            },
            hierarchy,
          },
          action,
        },
      ]
    } else {
      return [...acc, next]
    }
  }, [])

  const changedLocalAndOnlineWithCorrectedBookIds = changedLocalAndOnlineBooks.reduce(
    (acc, next) => {
      const {
        data: {
          onlineEntity,
          offlineEntity: { book, lines, cards, beats, hierarchy },
        },
        action,
      } = next
      if (action === 'DUPLICATE') {
        const newBookId = currentBookId
        currentBookId++
        return [
          ...acc,
          {
            data: {
              onlineEntity,
              offlineEntity: {
                book: {
                  ...book,
                  id: newBookId,
                },
                lines: lines.map((line) => {
                  return {
                    ...line,
                    bookId: newBookId,
                  }
                }),
                cards,
                beats: {
                  ...beats,
                  index: Object.values(beats.index).reduce((newIndex, nextBeat) => {
                    return {
                      [nextBeat.id]: {
                        ...nextBeat,
                        bookId: newBookId,
                      },
                      ...newIndex,
                    }
                  }, {}),
                },
                hierarchy,
              },
            },
            action,
          },
        ]
      } else {
        return [...acc, next]
      }
    },
    []
  )

  return {
    changedLocalAndOnlineWithCorrectedBookIds,
    localOnlyWithCorrectedBookIds,
  }
}

export const mergeImages = (localFile, remoteFile) => {
  const localImages = imagesSelector(localFile)
  const remoteImages = imagesSelector(remoteFile)

  const { localIdMap, allImages } = Object.values(localImages).reduce(
    (acc, next) => {
      const existingImage = acc.allImages[next.id]
      if (
        typeof existingImage === 'object' &&
        !isEqualTreatingUndefinedOrMissingAsTheSame(omit(existingImage, 'id'), omit(next, 'id'))
      ) {
        const nextImageId =
          Object.values(acc.allImages)
            .map(({ id }) => {
              return id
            })
            .reduce((maxImageId, indexedImageId) => {
              if (typeof indexedImageId === 'number') {
                return Math.max(maxImageId, indexedImageId)
              } else {
                return maxImageId
              }
            }, 0) + 1
        return {
          localIdMap: {
            ...acc.localIdMap,
            [next.id]: nextImageId,
          },
          allImages: {
            ...acc.allImages,
            [nextImageId]: {
              ...next,
              id: nextImageId,
            },
          },
        }
      } else {
        return {
          localIdMap: {
            ...acc.localIdMap,
            [next.id]: next.id,
          },
          allImages: {
            ...acc.allImages,
            [next.id]: next,
          },
        }
      }
    },
    {
      localIdMap: {},
      allImages: remoteImages,
    }
  )

  return {
    localIdMap,
    allImages,
  }
}

/**
 * @typedef Identifiable
 * @property {number} id
 */

/**
 * @param {Array<Identifiable>} existingEntities
 * @param {Array<ActionableData<Identifiable>>} localEntities
 * @param {Array<ActionableData<LocalAndOnline<Identifiable>>>} localAndRemoteEntities
 * @return {Record<String, number>}
 */
const translatedIds = (existingEntities, localEntities, localAndRemoteEntities) => {
  let currentId =
    existingEntities.reduce((acc, next) => {
      if (typeof next.id === 'number') {
        return Math.max(acc, next.id)
      } else {
        return acc
      }
    }, 0) + 1

  const existingIdMapping = existingEntities.reduce((acc, next) => {
    return {
      ...acc,
      [next.id]: next.id,
    }
  }, {})

  const localEntityMapping = localEntities.reduce((acc, next) => {
    if (next.action === 'INCLUDE') {
      return {
        ...acc,
        [next.data.id]: currentId++,
      }
    } else {
      return acc
    }
  }, existingIdMapping)

  return localAndRemoteEntities.reduce((acc, next) => {
    if (next.action === 'DUPLICATE') {
      return {
        ...acc,
        [next.data.onlineEntity.id]: currentId++,
      }
    } else {
      return acc
    }
  }, localEntityMapping)
}

// This function should be called on the result of bumping the book
// ids because it produces an identity mapping for the related ids.
// i.e. it trusts that bumping the book ids was already successful.
const translatedBookIds = (existingEntities, localOnlyBooks, localAndRemoteBooks) => {
  const accIds = (acc, { id }) => {
    return {
      ...acc,
      [id]: id,
    }
  }

  const bundleEntitiesToExtractIds = ['cards', 'lines']

  const existingIdMapping = existingEntities.reduce((acc, next) => {
    const bookId = next.id
    return {
      ...acc,
      books: { ...(acc.books ?? {}), [bookId]: bookId },
      hierarchy: { ...(acc.hierarchy ?? {}), [bookId]: bookId },
      beats: { ...(acc.beats ?? {}), [bookId]: bookId },
    }
  }, {})

  const localIdMapping = localOnlyBooks.reduce((acc, nextBundle) => {
    const bookId = nextBundle.data.book.id
    return bundleEntitiesToExtractIds.reduce(
      (bundleAcc, bundleKey) => {
        const bundleValue = nextBundle.data[bundleKey]
        return {
          ...bundleAcc,
          [bundleKey]: { ...(bundleAcc[bundleKey] ?? {}), ...bundleValue.reduce(accIds, {}) },
        }
      },
      {
        ...acc,
        books: { ...(acc.books ?? {}), [bookId]: bookId },
        hierarchy: { ...(acc.hierarchy ?? {}), [bookId]: bookId },
        beats: { ...(acc.beats ?? {}), [bookId]: bookId },
      }
    )
  }, existingIdMapping)

  return localAndRemoteBooks.reduce((acc, nextBundle) => {
    const bookId = nextBundle.data.offlineEntity.book.id
    return bundleEntitiesToExtractIds.reduce(
      (bundleAcc, bundleKey) => {
        const bundleValue = nextBundle.data.offlineEntity[bundleKey]
        return {
          ...bundleAcc,
          [bundleKey]: { ...(bundleAcc[bundleKey] ?? {}), ...bundleValue.reduce(accIds, {}) },
        }
      },
      {
        ...acc,
        books: { ...(acc.books ?? {}), [bookId]: bookId },
        hierarchy: { ...(acc.hierarchy ?? {}), [bookId]: bookId },
        beats: { ...(acc.beats ?? {}), [bookId]: bookId },
      }
    )
  }, localIdMapping)
}

export const actionConflictChanges = () => (dispatch, getState) => {
  const state = getState()
  const onlineFile = onlineFileForConflictResolutionSelector(state)
  const localOnly = localOnlyConflictChangesSelector(state)
  const changedLocalAndOnline = localAndOnlineConflictChangesSelector(state)
  const seriesConflict = seriesConflictSelector(state)
  const onlineState = { user: onlineFile }
  const mergedAttributes = mergeAttributes(
    // @ts-ignore
    attributesSelector(onlineState),
    attributesSelector(state)
  )
  const customAttributes = conflictResolutionCustomAttributesSelector(state)
  const mergedCategories = mergeCategories(
    // @ts-ignore
    allCategoriesSelector(onlineState),
    allCategoriesSelector(state)
  )
  const mergedImages = mergeImages(
    state,
    // @ts-ignore
    onlineState
  )
  const imagesWereAdded = !isEqualTreatingUndefinedOrMissingAsTheSame(
    mergedImages.allImages,
    imagesSelector(getState())
  )
  const { localOnlyWithCorrectedBookIds, changedLocalAndOnlineWithCorrectedBookIds } = bumpBookIds(
    // @ts-ignore
    onlineState,
    localOnly.books,
    changedLocalAndOnline.books
  )
  // @ts-ignore
  const onlineCustomAttributes = allCustomAttributesSelector(onlineState)
  // @ts-ignore
  const onlineAttributes = attributesSelector(onlineState)
  // @ts-ignore
  const onlineCategories = allCategoriesSelector(onlineState)
  if (
    typeof onlineFile === 'object' &&
    onlineFile &&
    (!isEmpty(localOnly.notes) ||
      !isEmpty(localOnly.characters) ||
      !isEmpty(localOnly.familyTrees) ||
      !isEmpty(localOnly.places) ||
      !isEmpty(localOnly.tags) ||
      !isEmpty(localOnly.books) ||
      !isEmpty(changedLocalAndOnline.notes) ||
      !isEmpty(changedLocalAndOnline.characters) ||
      !isEmpty(changedLocalAndOnline.familyTrees) ||
      !isEmpty(changedLocalAndOnline.places) ||
      !isEmpty(changedLocalAndOnline.tags) ||
      !isEmpty(changedLocalAndOnline.books) ||
      !isEqual(customAttributes, onlineCustomAttributes) ||
      !isEqual(mergedAttributes, onlineAttributes) ||
      !isEqual(mergedCategories, onlineCategories) ||
      imagesWereAdded)
  ) {
    const missingEntities = findMissingEntities(state, localOnly, changedLocalAndOnline)
    // @ts-ignore
    const currentNotes = allNotesSelector(onlineState)
    const noteIdMapping = translatedIds(currentNotes, localOnly.notes, changedLocalAndOnline.notes)
    // @ts-ignore
    const currentCharacters = allCharactersSelector(onlineState)
    const characterIdMapping = translatedIds(
      currentCharacters,
      localOnly.characters,
      changedLocalAndOnline.characters
    )
    // @ts-ignore
    const currentPlaces = allPlacesSelector(onlineState)
    const placeIdMapping = translatedIds(
      currentPlaces,
      localOnly.places,
      changedLocalAndOnline.places
    )
    // @ts-ignore
    const currentTags = allTagsSelector(onlineState)
    const tagIdMapping = translatedIds(currentTags, localOnly.tags, changedLocalAndOnline.tags)
    // @ts-ignore
    const currentBooks = allBooksAsArraySelector(onlineState)
    const bookIdMappings = translatedBookIds(
      currentBooks,
      localOnlyWithCorrectedBookIds,
      changedLocalAndOnlineWithCorrectedBookIds
    )
    dispatch({
      localOnly: {
        ...localOnly,
        books: localOnlyWithCorrectedBookIds,
      },
      changedLocalAndOnline: {
        ...changedLocalAndOnline,
        books: changedLocalAndOnlineWithCorrectedBookIds,
      },
      seriesConflict,
      missingEntities,
      onlineFile,
      mergedAttributes,
      customAttributes,
      mergedCategories,
      mergedImages,
      idMapping: {
        notes: noteIdMapping,
        characters: characterIdMapping,
        places: placeIdMapping,
        tags: tagIdMapping,
        cards: bookIdMappings.cards ?? {},
        lines: bookIdMappings.lines ?? {},
        beats: bookIdMappings.beats ?? {},
        books: bookIdMappings.books ?? {},
        hierarchy: bookIdMappings.hierarchy ?? {},
      },
      type: ACTION_CONFLICT_CHANGES,
    })
  }
}

export const bootedOfflineFileWhilstOnline = () => {
  return {
    type: BOOTED_OFFLINE_FILE_WHILST_ONLINE,
  }
}
