import { createSelector } from 'reselect'
import { groupBy, sortBy } from 'lodash'

import { outOfOrderSearch } from '../helpers/outOfOrderSearch'
import { beatsByPosition } from '../helpers/beats'

import { allCardsSelector } from './cardsFirstOrder'
import {
  beatsByBookSelector,
  currentTimelineSelector,
  outlineFilterSelector,
  outlineSearchTermSelector,
} from './secondOrder'
import { allLinesSelector } from './linesFirstOrder'
import { sortCardsInBeat } from '../helpers/cards'

function cardReduce(lineAttr, beatAttr, collapsedBeats, beatPositions) {
  return (acc, card) => {
    const cardBeatId = card[beatAttr]
    const beatId = (collapsedBeats && collapsedBeats.get(cardBeatId)) || cardBeatId
    const val = acc[`${card[lineAttr]}-${beatId}`]
    if (val && val.length) {
      const cards = [...val, card]
      const sortedCards = sortBy(sortBy(cards, 'positionWithinLine'), (card) => {
        const beatId = card[beatAttr]
        return beatPositions[beatId]
      })
      acc[`${card[lineAttr]}-${beatId}`] = sortedCards
    } else {
      acc[`${card[lineAttr]}-${beatId}`] = [card]
    }

    return acc
  }
}

const sortedBeatsByBookSelector = createSelector(
  beatsByBookSelector,
  beatsByPosition(() => true)
)

const stringifiedCardsByIdWhenSearchedSelector = createSelector(
  allCardsSelector,
  outlineSearchTermSelector,
  (cards, searchTerm) => {
    if (searchTerm && typeof searchTerm === 'string' && searchTerm !== '') {
      return cards.reduce((acc, nextCard) => {
        return {
          ...acc,
          [nextCard.id]: JSON.stringify(nextCard).toLowerCase(),
        }
      }, {})
    } else {
      return {}
    }
  }
)

export const visibleOutlineCardsSelector = createSelector(
  allCardsSelector,
  outlineFilterSelector,
  (cards, outlineFilter) => {
    return outlineFilter?.length ? cards.filter((c) => outlineFilter.includes(c.lineId)) : cards
  }
)

export const linesByBookSelector = createSelector(
  allLinesSelector,
  currentTimelineSelector,
  (lines, bookId) => {
    return lines.filter((l) => l && l.bookId == bookId)
  }
)

const sortedLinesByBookSelector = createSelector(linesByBookSelector, (lines) =>
  sortBy(lines, 'position')
)

export const outlineSearchedCardsSelector = createSelector(
  visibleOutlineCardsSelector,
  sortedBeatsByBookSelector,
  outlineSearchTermSelector,
  stringifiedCardsByIdWhenSearchedSelector,
  (cards, allSortedBeats, outlineSearchTerm, stringifiedCards) => {
    const beatIds = allSortedBeats.map(({ id }) => id)
    const beatPositions = beatIds.map((x) => x)
    beatIds.forEach((beatId, index) => (beatPositions[beatId] = index))
    const lowerCaseSearchTerms =
      outlineSearchTerm &&
      outlineSearchTerm
        .toLowerCase()
        .split(' ')
        .filter((x) => x)
    return lowerCaseSearchTerms
      ? cards.filter(({ id }) => {
          return outOfOrderSearch(lowerCaseSearchTerms, stringifiedCards[id])
        })
      : cards
  }
)

export const outlineSearchedCardMapSelector = createSelector(
  visibleOutlineCardsSelector,
  sortedBeatsByBookSelector,
  outlineSearchTermSelector,
  stringifiedCardsByIdWhenSearchedSelector,
  (cards, allSortedBeats, outlineSearchTerm, stringifiedCards) => {
    const beatIds = allSortedBeats.map(({ id }) => id)
    const beatPositions = beatIds.map((x) => x)
    beatIds.forEach((beatId, index) => (beatPositions[beatId] = index))
    const lowerCaseSearchTerms =
      outlineSearchTerm &&
      outlineSearchTerm
        .toLowerCase()
        .split(' ')
        .filter((x) => x)
    const filteredCards = lowerCaseSearchTerms
      ? cards.filter(({ id }) => {
          return outOfOrderSearch(lowerCaseSearchTerms, stringifiedCards[id])
        })
      : cards
    return filteredCards.reduce(cardReduce('lineId', 'beatId', new Map(), beatPositions), {})
  }
)

export const allCardsByBeatSelector = createSelector(allCardsSelector, (cards) => {
  return groupBy(
    cards.map((c) => ({
      ...c,
      positionInBeat: c.positionInBeat || c.positionInChapter || 0,
      beatId: c.beatId ? Number(c.beatId) : null,
    })),
    'beatId'
  )
})

const beatSelector = (_state, beat) => beat

export const allCardsInBeatSelector = createSelector(
  allCardsByBeatSelector,
  beatSelector,
  sortedLinesByBookSelector,
  (cards, beat, sortedLines) => {
    const cardsInBeat = cards[beat.id] ?? []
    return sortCardsInBeat(beat.autoOutlineSort, cardsInBeat, sortedLines)
  }
)

export const visibleCardsInBeatSelector = createSelector(
  outlineSearchedCardsSelector,
  beatSelector,
  sortedLinesByBookSelector,
  (cards, beat, sortedLines) => {
    const cardsInBeat = cards.filter((c) => c.beatId == beat.id)
    return sortCardsInBeat(beat.autoOutlineSort, cardsInBeat, sortedLines)
  }
)
