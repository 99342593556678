import { newFileFamilyTrees } from '../../store/newFileState'

function migrate(data) {
  if (typeof data.familyTrees !== 'object' || !data.familyTrees) {
    return {
      ...data,
      familyTrees: newFileFamilyTrees,
    }
  } else {
    return data
  }
}

export default migrate
